import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI, PmdAPI } from "apis";
import { CompanyData } from "models";

import { CompanyStatus } from "shared/constants";
import { ManufacturingItemStatus } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";
import getAuthorsComments from "shared/utils/getAuthorsComments";
import getCategoriesWithHierarchy from "shared/utils/getCategoriesWithHierarchy";
import getContacts from "shared/utils/getContacts";

async function getManufacturedItems({
  tradeItemId,
  ownerCompanyId,
}: {
  tradeItemId: string;
  ownerCompanyId: string;
}) {
  const {
    data: { manufacturedItems },
  } = await PmdAPI.getManufacturedItemsByFilters({
    ownerCompanyId,
    tradeItemId,
  });

  const companyIds = manufacturedItems
    .flatMap(({ supplierCompanyId, packerCompanyId }) => [
      packerCompanyId,
      supplierCompanyId,
    ])
    .filter(id => id);

  let companies: CompanyData[] = [];

  if (companyIds.length > 0) {
    companies = await CumdAPI.getCompaniesByFilters(
      { companyIds },
      { relatedOwnerCompanyId: ownerCompanyId, includePrivate: true }
    ).then(({ data }) => data.companies);
  }

  const manufacturedItemsForDetails = manufacturedItems
    .map(({ supplierCompanyId, packerCompanyId, ...rest }) => {
      let mappedSupplierCompanyId: {
        value?: string;
        label?: string;
        companyRelationExternalIdentifier?: string;
        companyDisplayName: string;
        companyRelationExternalName?: string;
        companyStatus?: CompanyStatus;
        companyRelationStatus?: boolean;
        companyType?: string;
      };
      let mappedPackerCompanyId: {
        value?: string;
        label?: string;
      };
      if (supplierCompanyId) {
        const company = companies.find(
          ({ companyId }) => companyId === supplierCompanyId
        );

        mappedSupplierCompanyId = {
          value: supplierCompanyId,
          label: buildCompanyTitle(company),
          companyRelationExternalIdentifier:
            company?.companyRelationExternalIdentifier,
          companyDisplayName: company?.companyDisplayName,
          companyRelationExternalName: company?.companyRelationExternalName,
          companyStatus: company?.companyStatus as CompanyStatus,
          companyRelationStatus: company?.isCompanyRelationStatusEnabled,
          companyType: company?.companyType,
        };
      }
      if (packerCompanyId) {
        mappedPackerCompanyId = {
          value: packerCompanyId,
          label: companies.find(
            ({ companyId }) => companyId === packerCompanyId
          )?.companyDisplayName,
        };
      }
      return {
        ...rest,
        packerCompanyId: mappedPackerCompanyId,
        supplierCompanyId: mappedSupplierCompanyId,
      };
    })
    .sort((a, b) => {
      if (a.manufacturedItemStatus !== b.manufacturedItemStatus) {
        return a.manufacturedItemStatus === ManufacturingItemStatus.ACTIVE
          ? -1
          : 1;
      } else if (
        a.manufacturedItemStatus === ManufacturingItemStatus.ACTIVE &&
        b.manufacturedItemStatus === ManufacturingItemStatus.ACTIVE
      ) {
        return (
          new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
        );
      } else if (
        a.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE &&
        b.manufacturedItemName === ManufacturingItemStatus.INACTIVE
      ) {
        return (
          new Date(b.lastStatusUpdateDateTime).getTime() -
          new Date(a.lastStatusUpdateDateTime).getTime()
        );
      }
      return 0;
    });

  return manufacturedItemsForDetails;
}

export const fetchProduct = createAsyncThunk(
  "productDetails/fetchProduct",
  async (
    {
      id,
      ownerCompanyId,
      languageCode,
    }: { id: string; ownerCompanyId: string; languageCode: string },
    thunkAPI
  ) => {
    try {
      const {
        data: {
          tradeItemStatus,
          itemName,
          commentIds,
          netContentUnit,
          netContentValue,
          category,
          comments,
          ...product
        },
      } = await PmdAPI.getTradeItemById(id);

      const cateoriesId = product.productCategories.map(
        category => category.categoryItemId
      );

      const [contacts, manufacturedItems, categories, commentsWithUserData] =
        await Promise.all([
          getContacts(product.contacts),
          getManufacturedItems({ tradeItemId: id, ownerCompanyId }),
          getCategoriesWithHierarchy(cateoriesId, languageCode),
          getAuthorsComments({ comments }),
        ]);
      product.retailerCustomizedAttributes.sort((a, b) =>
        a.retailerLabel.localeCompare(b.retailerLabel)
      );
      return {
        tradeItemStatusId: tradeItemStatus,
        tradeItemName: itemName,
        ...product,
        contacts,
        manufacturedItems,
        comments: commentsWithUserData,
        categories,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);

export const fetchSupplierProduct = createAsyncThunk(
  "productDetails/fetchSupplierProduct",
  async (
    { id, languageCode }: { id: string; languageCode: string },
    thunkAPI
  ) => {
    try {
      const { data: manufacturedItemData } =
        await PmdAPI.getManufacturedItemById(id);
      const { data: tradeItemData } = await PmdAPI.getTradeItemById(
        manufacturedItemData.tradeItemId
      );
      const cateoriesId = tradeItemData.productCategories.map(
        category => category.categoryItemId
      );

      const [companyData, categories, brandData] = await Promise.all([
        CumdAPI.getCompanyById(manufacturedItemData.ownerCompanyId),
        getCategoriesWithHierarchy(cateoriesId, languageCode),
        PmdAPI.getBrandById(tradeItemData.brandId, { languageCode }),
      ]);
      return {
        tradeItemData: tradeItemData,
        ...manufacturedItemData,
        companyData: companyData.data,
        brandData: brandData.data,
        categories,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
