import { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { TagV1 as Tag } from "@trace-one/design-system";
import cn from "classnames";

import { CumdAPI } from "apis";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import AsyncSearchSelect from "components/AsyncSearchSelect";
import { CompanyType, dropdownFilterCount } from "shared/constants";
import { SelectOption } from "shared/typings";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import styles from "./AsyncCompanySelect.module.less";

const getSupplierTagName = () => {
  return (
    <Tag
      size="small"
      label={<FormattedMessage id="general.inactive" />}
      color="grey"
      mode="light"
    />
  );
};

export const getSupplierName = (
  companyDisplayName,
  showStatusEnabled,
  company
) => {
  const tagName = !showStatusEnabled && getSupplierTagName();
  return (
    <div className={styles.brandTag}>
      <div className={styles.brandNameTitle}>
        <span
          className={cn(
            company?.companyType === CompanyType.PRIVATE &&
              styles.privateCompanyTitle,
            !showStatusEnabled && styles.brandName,
            styles.companyNameTitle
          )}
        >
          {companyDisplayName}
        </span>
        {company?.companyRelationExternalName && (
          <>
            <span className={styles.secondaryName}>
              {company.companyDisplayName}
            </span>
          </>
        )}
      </div>
      {tagName && <div>{tagName}</div>}
    </div>
  );
};

const AsyncCompanySelect = ({
  value,
  onChange,
  label,
  companyActivityId,
  defaultOptions,
  isSupplierInput,
  isRetailer,
  onLabelChange,
  includePrivate,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [optionLabel, setOptionLabel] = useState(label);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [companiesDefaultOptions, setCompaniesDefaultOptions] =
    useState<SelectOption[]>(defaultOptions);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const totalCount = useRef(dropdownFilterCount);

  useEffect(() => {
    setCompaniesDefaultOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    if (value) {
      CumdAPI.getCompaniesByFilters(
        { companyIds: [value] },
        {
          relatedOwnerCompanyId: isRetailer ? ownerCompanyId : undefined,
          relatedAssociatedCompanyId: isRetailer ? undefined : ownerCompanyId,
          includePrivate: includePrivate,
        }
      ).then(({ data }) => {
        if (data.companies.length === 1) {
          const company = data.companies[0];
          setOptionLabel(
            isRetailer
              ? getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  {
                    companyType: company.companyType,
                  }
                )
              : label
          );
          onLabelChange(
            isRetailer
              ? buildCompanyTitle(company)
              : company?.companyDisplayName
          );
        }
      });
    }
  }, [value]);

  return (
    <div className={styles.root}>
      <AsyncSearchSelect
        {...rest}
        showSearch
        defaultOptions={companiesDefaultOptions}
        value={value ? { value, label: optionLabel } : undefined}
        searchValue={searchValue}
        onChange={(_value, option) => {
          setOptionLabel(option?.name);
          onChange(_value);
          onLabelChange(option?.name);
        }}
        onAsyncSearch={({ searchValue }) => {
          return CumdAPI.getCompaniesByFilters(
            {},
            {
              searchName: searchValue,
              relatedOwnerCompanyId: isRetailer ? ownerCompanyId : undefined,
              relatedAssociatedCompanyId: isRetailer
                ? undefined
                : ownerCompanyId,
              companyActivityId,
              includePrivate: includePrivate,
              isCompanyRelationStatusEnabled: isRetailer ? undefined : true,
            }
          ).then(({ data }) => {
            return data.companies.map(
              ({ companyId, isCompanyRelationStatusEnabled, ...rest }) => ({
                value: companyId,
                labelValue: isRetailer
                  ? buildCompanyTitle(rest)
                  : rest.companyDisplayName,
                name: isRetailer
                  ? getSupplierName(
                      buildCompanyTitle(rest),
                      isCompanyRelationStatusEnabled,
                      rest
                    )
                  : rest.companyDisplayName,
                title: isRetailer
                  ? buildCompanyTitle(rest)
                  : rest.companyDisplayName,
              })
            );
          });
        }}
        onPopupScroll={async e => {
          e.persist();
          let target = e.target;

          if (
            Math.round(target.scrollTop + target.offsetHeight) >
            target.scrollHeight - 3
          ) {
            if (usersLoading) {
              return;
            }

            setUsersLoading(true);
            totalCount.current = totalCount.current + dropdownFilterCount;
            const { data } = await CumdAPI.getCompaniesByFilters(
              {},
              {
                skip: 0,
                take: totalCount.current,
                relatedOwnerCompanyId: isRetailer ? ownerCompanyId : undefined,
                relatedAssociatedCompanyId: isRetailer
                  ? undefined
                  : ownerCompanyId,
                companyActivityId,
                includePrivate: isRetailer ? includePrivate : undefined,
                isCompanyRelationStatusEnabled: true,
              }
            );
            const companyOptions = isRetailer
              ? data.companies.map(company => ({
                  value: company.companyId,
                  name: getSupplierName(
                    buildCompanyTitle(company),
                    company.isCompanyRelationStatusEnabled,
                    company
                  ),
                  title: buildCompanyTitle(company),
                }))
              : data.companies.map(c => ({
                  value: c.companyId,
                  name: c.companyDisplayName,
                  companyStatus: c.companyStatus,
                }));
            setCompaniesDefaultOptions(companyOptions);
            setUsersLoading(false);
          }
        }}
        minLengthToSearch={3}
        labelInValue
        onSearch={value => {
          setSearchValue(value);
        }}
        allowClear
      />
    </div>
  );
};

export default AsyncCompanySelect;
