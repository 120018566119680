import {
  TradeItemData,
  ProductKpiData,
  ManufacturedFilterItemData,
  CompanyData,
} from "models";

type State = {
  products: TradeItemData[];
  companies: CompanyData[];
  manufacturedItems: ManufacturedFilterItemData[];
  productsSkipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isProductsLoading: boolean;
  hasProductsError: boolean;
  kpiData: ProductKpiData;
  isKpiDataLoading: boolean;
  isExportSelectedProductsLoading: boolean;
  isExportSelectedManufacturedItemsLoading: boolean;
};

export const initialState: State = {
  products: [],
  companies: [],
  manufacturedItems: [],
  productsSkipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  kpiData: undefined,
  isProductsLoading: false,
  hasProductsError: false,
  isKpiDataLoading: true,
  isExportSelectedProductsLoading: false,
  isExportSelectedManufacturedItemsLoading: false,
};
