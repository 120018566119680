import { Row, Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import Panel from "components/Panel";

import SiteBloc from "../SiteIdentification/SiteBloc";

import CustomAttributesBloc from "./CustomAttributesBloc";
import SiteUsageBloc from "./SiteUsageBloc";

const PrivateInformationBloc = () => {
  return (
    <Row gutter={[32, 32]}>
      <Col {...PageCol.FULL}>
        <Panel>
          <SiteUsageBloc />

          <CustomAttributesBloc />
        </Panel>
      </Col>
      <Col {...PageCol.FULL}>
        <SiteBloc />
      </Col>
    </Row>
  );
};

export default PrivateInformationBloc;
