import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, UserCard } from "@trace-one/design-system";
import { SectionsIds } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";
import {
  selectRetailerRelatedUsers,
  selectRetailerRelation,
  selectRetailerSite,
} from "reduxStore/siteRetailerDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { UserStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../../SiteRetailerDetails.module.less";

const ContactsBloc = () => {
  const intl = useIntl();
  const history = useHistory();
  const site = useSelector(selectRetailerSite);
  const users = useSelector(selectRetailerRelatedUsers);
  const relation = useSelector(selectRetailerRelation);
  const teamReponsabilities = useSelector(selectTeamMemberResponsibilities);
  const { siteRelationsWrite } = usePermissions();
  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "siteForm.contactsTitle" })
            )}
          </Heading>
        }
      />
      {siteRelationsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          data-test-id="privateInfo-button"
          iconName="edit"
          iconPlacement="left"
          onClick={() => {
            history.push({
              pathname: `/sites/edit/${site.id}`,
              hash: SectionsIds.CONTACTS,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteDetails.peopleInCharge" })}
          </Heading>
        }
        testId="siteUsage-people"
      >
        {relation.contacts?.map((contact, idx) => {
          const isUserInactive = contact.userStatus !== UserStatus.ENABLED;
          const userResponsabilty = teamReponsabilities.find(
            resp => contact.responsibilityId === resp.id
          );

          return (
            <UserCard
              size="xs"
              disabled={isUserInactive}
              key={idx}
              name={contact.userName}
              title={userResponsabilty?.text}
              photoUrl={contact.userPhotoUrl}
              className="my-0-5"
              inactiveTooltipText={intl.formatMessage({
                id: "general.userInactive",
              })}
            />
          );
        })}
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteDetails.emergencyContact" })}
          </Heading>
        }
        bordered
        testId="contacts-emergency"
      >
        <Paragraph size="m">
          {site?.emergencyPhone && <div>{site.emergencyPhone}</div>}
          {site?.siteEmail && <div>{site.siteEmail}</div>}
          {site?.sitePhone && <div>{site.sitePhone}</div>}
          {site?.siteFax && <div>{site.siteFax}</div>}
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteDetails.additionalContacts" })}
          </Heading>
        }
        testId="contacts-contacts"
      >
        {!!site?.contactUserIds.length && (
          <div className={styles.additionalContact}>
            {site.contactUserIds.map(userId => {
              const { label, userPhotoUrl, userJobTitle, userStatus } =
                users.find(({ value }) => value === userId) || {};
              const isUserInactive = userStatus !== UserStatus.ENABLED;

              return (
                <div key={userId} className="m-0-5">
                  <UserCard
                    size="xs"
                    disabled={isUserInactive}
                    name={label}
                    title={userJobTitle}
                    photoUrl={userPhotoUrl}
                    inactiveTooltipText={intl.formatMessage({
                      id: "general.userInactive",
                    })}
                  />
                </div>
              );
            })}
          </div>
        )}
      </Label>
    </Panel>
  );
};

export default ContactsBloc;
