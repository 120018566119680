import { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { selectUserId } from "reduxStore/user/selectors";

import { StorageType } from "shared/webStorage/storage";

const isObject = obj => typeof obj === "object" && obj !== null;

type SearchFiltersOptions<T> = {
  clearedFilters?: T;
  resetPageNumber?: () => void;
  setSelectedRowKeys?: (rowKeys: string[]) => void;
  setAllSelected?: (boolean) => void;
  webStorage?: StorageType;
};

function useInitialSearchFilters<T>({
  webStorage,
  initialFilters,
  userId,
}: {
  webStorage?: StorageType;
  initialFilters: T;
  userId: string;
}) {
  const { searchText, filterObj } = useMemo<{
    searchText: string;
    filterObj: T;
  }>(() => {
    const result = webStorage?.get();
    // Return initialValue if webStorage does not store value
    const initialSearchFilters = { filterObj: initialFilters, searchText: "" };

    if (!isObject(result)) {
      return initialSearchFilters;
    }

    const initialFilterObjKeys = Object.keys(initialFilters);

    const isValid =
      result.userId === userId &&
      isObject(result.filterObj) &&
      Object.keys(result.filterObj).length > 0 &&
      Object.keys(result.filterObj)?.every(key =>
        initialFilterObjKeys.includes(key)
      );
    const searchText = result.searchText ?? "";
    if (isValid) {
      return result;
    } else {
      return { ...initialSearchFilters, searchText };
    }
  }, []);

  return {
    searchText,
    filterObj,
  };
}

export default function useSearchFilters<T extends object>(
  initialFilters: T,
  {
    clearedFilters,
    resetPageNumber,
    setSelectedRowKeys,
    setAllSelected,
    webStorage,
  }: SearchFiltersOptions<T> = {}
) {
  const userId = useSelector(selectUserId);
  const { search } = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(search).entries());
  const history = useHistory<{
    productListId: string;
    productListItemId: string;
  }>();
  const { productListId, productListItemId } =
    history.location?.state ?? queryParams ?? {};

  const { productId, siteId, supplierId } = queryParams ?? {};

  const initialSearchFilters = useInitialSearchFilters({
    webStorage,
    initialFilters,
    userId,
  });

  const [searchText, _setSearchText] = useState(
    initialSearchFilters.searchText
  );
  let changedFilterObj;
  if (productListId) {
    changedFilterObj = {
      ...clearedFilters,
      productCategory: {
        categoryId: productListId,
        categoryItemId: productListItemId ?? "",
      },
    };
    window.history.replaceState({}, document.title);
  }

  if (productId || siteId || supplierId) {
    changedFilterObj = {
      ...clearedFilters,
      tradeItemId: productId,
      siteId,
      ownerCompanyId: supplierId,
    };
  }

  const [filterObj, setFilterObj] = useState(
    changedFilterObj ?? initialSearchFilters.filterObj
  );

  useEffect(() => {
    webStorage?.set({ searchText, filterObj, userId });
  }, [searchText, filterObj]);

  const resetPageNumberAndRowKeys = useCallback(() => {
    resetPageNumber && resetPageNumber();
    setSelectedRowKeys && setSelectedRowKeys([]);
    setAllSelected && setAllSelected(false);
  }, [resetPageNumber, setSelectedRowKeys]);

  const setSearchText = useCallback(
    (value: string) => {
      resetPageNumberAndRowKeys();
      _setSearchText(value);
    },
    [resetPageNumberAndRowKeys, _setSearchText]
  );

  const removeAllFiltersAndResetPageNumber = useCallback(() => {
    setFilterObj(clearedFilters ?? initialFilters);
    resetPageNumberAndRowKeys();
  }, [resetPageNumberAndRowKeys, setFilterObj]);

  const resetAllFiltersAndResetPageNumber = useCallback(
    (initialFilters: T) => {
      setFilterObj(initialFilters);
      resetPageNumberAndRowKeys();
    },
    [resetPageNumberAndRowKeys, setFilterObj]
  );

  const mergeFilters = useCallback(
    (value: Partial<T> | ((prevState: T) => T)) => {
      resetPageNumberAndRowKeys();
      if (typeof value === "function") {
        setFilterObj(value);
      } else {
        setFilterObj(prev => ({
          ...prev,
          ...value,
        }));
      }
    },
    [resetPageNumberAndRowKeys, setFilterObj]
  );

  return {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    resetAllFiltersAndResetPageNumber,
    removeAllFiltersAndResetPageNumber,
    setFilterObj,
  };
}
