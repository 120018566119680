import { useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Heading, TagV1 as Tag } from "@trace-one/design-system";
import { NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM } from "pages/Products/constants";
import { compose } from "redux";

import { useAppDispatch } from "reduxStore";
import { fetchSupplierProduct } from "reduxStore/productDetails/asyncActions";
import { selectSupplierProductDetailsData } from "reduxStore/productDetails/selectors";
import { clearProductDetails } from "reduxStore/productDetails/slice";
import { setBackButtonData } from "reduxStore/shared/slice";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import { ManufacturingItemStatus } from "shared/constants";
import {
  withCountries,
  withCustomProductTypes,
  withLanguages,
  withNetContentMeasures,
} from "shared/hocs";

import ProductInformationTab from "./components/ProductInformationTab";

const enhance = compose<React.FC>(
  withCountries(),
  withNetContentMeasures({
    includeParentItems: true,
    parentItemId: NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
  }),
  withCustomProductTypes(),
  withLanguages()
);

const ProductSupplierDetails = ({ isInitiated }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId?: string }>();
  const {
    productSupplierData: {
      data: productData,
      isLoading: isProductLoading,
      hasError,
      errorStatus,
    },
  } = useSelector(selectSupplierProductDetailsData);
  const languageCode = useSelector(selectUserLanguageCode);
  const productIsDisabled =
    productData.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE;

  const refetchProduct = useCallback(() => {
    dispatch(fetchSupplierProduct({ id: productId, languageCode }));
  }, [productId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProductDetails());
    };
  }, []);

  useEffect(() => {
    refetchProduct();
  }, [refetchProduct]);

  useEffect(() => {
    if (productIsDisabled) {
      dispatch(
        setBackButtonData({
          fromUrl: `/products/list`,
        })
      );
    }
  }, [productIsDisabled]);

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.generalInformation" })}
        </Heading>
      ),
      content: <ProductInformationTab />,
    },
  ];

  if (hasError) return <ErrorPage status={errorStatus} />;
  if (!isInitiated || isProductLoading) return <Spinner underHeader />;

  const ProductStatusTag = () => {
    if (
      productData.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE
    ) {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.archived" })}
          color="grey"
          mode="light"
        />
      );
    }

    return null;
  };

  return (
    <div>
      <HeaderDetails
        withBackNav
        title={
          productData.manufacturedItemName ||
          productData.tradeItemData?.itemName
        }
        subtitle={<></>}
        status={<ProductStatusTag />}
        tabs={tabList}
        headerIcon="product-add"
        haveActionItems
      />
    </div>
  );
};

export default enhance(ProductSupplierDetails);
