import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Tooltip, TagV1 as Tag, Icon } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";

import {
  TradeItemData,
  ReferenceListItemData,
  BrandData,
  ManufacturedFilterItemData,
  CompanyData,
} from "models";

import {
  selectNetContentMeasuresData,
  selectTradeItemStatusesData,
} from "reduxStore/shared/selectors";

import { TradeItemStatus, ManufacturingItemStatus } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import styles from "../../../ProductList/ProductList.module.less";
import { ProductColumn, ManufacturedItemColumn } from "../models";
import ProductAction from "../ProductAction";

const mapProductToColumn = ({
  product,
  productTranslations,
}: {
  product: TradeItemData;
  productTranslations: {
    netContentMeasures: ReferenceListItemData;
    tradeItemStatuses: ReferenceListItemData;
  };
}): ProductColumn => {
  const {
    id,
    itemName,
    netContents,
    packagingFormat,
    brandName,
    gtin,
    retailerCustomizedAttributes,
    tradeItemStatus,
  } = product;

  return {
    key: id,
    itemName,
    netContents: netContents
      .map(({ unitOfMeasure, value }) => {
        const text = productTranslations.netContentMeasures[unitOfMeasure];
        return [value, text].filter(i => i).join(" ");
      })
      .join(" ; "),
    packagingFormat,
    brandName,
    gtin,
    internalCode: retailerCustomizedAttributes
      .map(({ retailerCode }) => retailerCode)
      .join(" ; "),
    tradeItemStatus,
    rawProductData: product,
  };
};

const mapManufacturedItemsToColumn = ({
  product,
  productTranslations,
  companiesList,
}: {
  product: ManufacturedFilterItemData;
  companiesList: { [companyId: string]: CompanyData };
  productTranslations: {
    netContentMeasures: ReferenceListItemData;
  };
}): ManufacturedItemColumn => {
  const {
    id,
    manufacturedItemName,
    tradeItemName,
    gtin,
    netContents,
    brandName,
    manufacturedItemStatus,
    ownerCompanyId,
  } = product;

  return {
    key: id,
    itemName: manufacturedItemName || tradeItemName,
    retailerName: buildCompanyTitle(companiesList[ownerCompanyId]),
    netContents: netContents
      .map(({ unitOfMeasure, value }) => {
        const text = productTranslations.netContentMeasures[unitOfMeasure];
        return [value, text].filter(i => i).join(" ");
      })
      .join(" ; "),
    brandName,
    gtin,
    itemStatus: manufacturedItemStatus,
  };
};

export default function useProductTable({
  products,
  manufacturedItems,
  brands,
  refetchProducts,
  companiesList,
  handleContactModel,
}: {
  products?: TradeItemData[];
  manufacturedItems?: ManufacturedFilterItemData[];
  brands: BrandData[];
  refetchProducts: () => void;
  companiesList: { [companyId: string]: CompanyData };
  handleContactModel: (vlaue: {}) => void;
}) {
  const intl = useIntl();
  const history = useHistory();

  const { data: tradeItemStatuses } = useSelector(selectTradeItemStatusesData);
  const { data: netContentMeasures } = useSelector(
    selectNetContentMeasuresData
  );

  const productTranslations = {
    tradeItemStatuses: tradeItemStatuses.reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: current.text,
      }),
      {}
    ),
    netContentMeasures: netContentMeasures.reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: current.text,
      }),
      {}
    ),
  };

  const columns: ColumnsType<ProductColumn> = [
    {
      title: intl.formatMessage({
        id: "productsListPage.table.productName",
      }),
      dataIndex: "itemName",
      key: "productName",
      width: 110,
      ellipsis: true,
      // TODO CHANGE TO DETAIL PAGE
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            history.push(`/products/${record.key}`);
          }}
        >
          <div>{text}</div>
        </Button>
      ),
    },
    {
      title: intl.formatMessage({
        id: "productsListPage.table.netContents",
      }),
      dataIndex: "netContents",
      key: "netContents",
      width: 110,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.netContents} placement="top">
            <div className={styles.productListTooltip}>
              {record.netContents}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "productsListPage.table.packaging",
      }),
      dataIndex: "packagingFormat",
      key: "packagingFormat",
      width: 80,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.packagingFormat} placement="top">
            <div className={styles.productListTooltip}>
              {record.packagingFormat}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "general.brand",
      }),
      dataIndex: "brandName",
      key: "brandName",
      width: 150,
      ellipsis: true,
      render: (_, record) => {
        const { brandName, isActive } =
          brands.find(({ id }) => id === record?.rawProductData?.brandId) ?? {};

        const getTagName = (brandname, isActive) => {
          const tagName = !isActive && (
            <Tag
              size="small"
              label={intl.formatMessage({ id: "general.archived" })}
              color="grey"
              mode="light"
            />
          );
          return (
            brandName && (
              <div style={{ display: "flex" }}>
                <div className={styles.productListTooltip}> {brandname} </div>{" "}
                <span style={{ marginLeft: 12 }}>{tagName} </span>
              </div>
            )
          );
        };
        return (
          <>
            <Tooltip showFullText text={record.brandName} placement="top">
              {getTagName(brandName, isActive)}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "productsListPage.table.GTIN",
      }),
      dataIndex: "gtin",
      key: "gtin",
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.gtin} placement="top">
            <div className={styles.productListTooltip}>{record.gtin}</div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "productsListPage.table.privateAttributes",
      }),
      dataIndex: "internalCode",
      key: "internalCode",
      width: 110,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.internalCode} placement="top">
            <div className={styles.productListTooltip}>
              {record.internalCode}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "siteForm.contact",
      }),
      dataIndex: "sitecontact",
      key: "sitecontact",
      width: 110,
      ellipsis: true,
      render: (_, record) => {
        let count = record?.rawProductData?.contacts?.length;
        return (
          <>
            {count === 0 ? (
              "-"
            ) : (
              <>
                <Button
                  type="link"
                  data-test-id="md-productList-modal"
                  onClick={() => handleContactModel(record.rawProductData)}
                >
                  <div>{count}</div>
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "general.archived",
      }),
      dataIndex: "tradeItemStatus",
      key: "tradeItemStatus",
      width: 100,
      ellipsis: true,
      render: tradeItemStatus => {
        return (
          <span>
            <FormattedMessage
              id={
                tradeItemStatus === TradeItemStatus.ACTIVE
                  ? "general.no"
                  : "general.yes"
              }
            />
          </span>
        );
      },
    },
    {
      title: <Icon style={{ margin: "auto" }} name="settings" color="white" />,
      dataIndex: "actions",
      key: "actions",
      width: 40,
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <ProductAction record={record} refetchProducts={refetchProducts} />
      ),
    },
  ];

  const supplierColumns: ColumnsType<ManufacturedItemColumn> = [
    {
      title: intl.formatMessage({
        id: "productsListPage.table.productName",
      }),
      dataIndex: "itemName",
      key: "itemName",
      width: 130,
      ellipsis: true,
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            history.push(`/products/${record.key}`);
          }}
        >
          <div>{record.itemName}</div>
        </Button>
      ),
    },
    {
      title: intl.formatMessage({
        id: "general.retailer",
      }),
      dataIndex: "retailerName",
      key: "retailerName",
      width: 120,
      ellipsis: true,
      render: (_, record) => (
        <Tooltip showFullText text={record.retailerName} placement="top">
          <div className={styles.productListTooltip}>{record.retailerName}</div>
        </Tooltip>
      ),
    },
    {
      title: intl.formatMessage({
        id: "productsListPage.table.GTIN",
      }),
      dataIndex: "gtin",
      key: "gtin",
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <Tooltip showFullText text={record.gtin} placement="top">
          <div className={styles.productListTooltip}>{record.gtin}</div>
        </Tooltip>
      ),
    },
    {
      title: intl.formatMessage({
        id: "general.brand",
      }),
      dataIndex: "brandName",
      key: "brandName",
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <Tooltip showFullText text={record.brandName} placement="top">
          <div className={styles.productListTooltip}>{record.brandName}</div>
        </Tooltip>
      ),
    },
    {
      title: intl.formatMessage({
        id: "productsListPage.table.netContents",
      }),
      dataIndex: "netContents",
      key: "netContents",
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <Tooltip showFullText text={record.netContents} placement="top">
          <div className={styles.productListTooltip}>{record.netContents}</div>
        </Tooltip>
      ),
    },
    {
      title: intl.formatMessage({
        id: "general.archived",
      }),
      dataIndex: "itemStatus",
      key: "itemStatus",
      width: 70,
      ellipsis: true,
      render: status => {
        return (
          <span>
            <FormattedMessage
              id={
                status === ManufacturingItemStatus.ACTIVE
                  ? "general.no"
                  : "general.yes"
              }
            />
          </span>
        );
      },
    },
    {
      title: <Icon style={{ margin: "auto" }} name="settings" color="white" />,
      dataIndex: "actions",
      key: "actions",
      width: 40,
      fixed: "right",
      ellipsis: true,
    },
  ];

  const data = products.map(product =>
    mapProductToColumn({
      product,
      productTranslations,
    })
  );
  const supplierData = manufacturedItems.map(product =>
    mapManufacturedItemsToColumn({
      product,
      productTranslations,
      companiesList,
    })
  );

  return { columns, data, supplierColumns, supplierData };
}
