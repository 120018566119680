import React, { memo, useEffect, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, TagV1 as Tag, ActionBanner } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import {
  ContactColumns,
  ProductFilter,
} from "pages/Products/pages/ProductList/ProductTable/models";
import PropTypes from "prop-types";

import { PmdAPI } from "apis";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import { setProductBrandId } from "reduxStore/productDetails/slice";
import {
  fetchProducts,
  fetchManufacturedItemsByFilters,
} from "reduxStore/productList/asyncActions";
import {
  selectProducts,
  selectSupplierProducts,
  selectIsProductTableLoading,
  selectProductsSkipAndTakeQueryStats,
  selectCompaniesMapForTable,
} from "reduxStore/productList/selectors";
import { clearProducts } from "reduxStore/productList/slice";
import { fetchRelatedCompanies } from "reduxStore/shared/asyncActions";
import {
  selectBrandsData,
  selectNetContentMeasuresData,
  selectTradeItemStatusesData,
} from "reduxStore/shared/selectors";
import {
  fetchCompaniesSupplier,
  fetchAllCompaniesSupplier,
} from "reduxStore/siteList/asyncActions";
import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";
import {
  setSupplierId,
  setSupplierName,
} from "reduxStore/SupplierDetails/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ContactsModal from "components/ContactsModal";
import Table from "components/Table";
import {
  TradeItemStatus,
  ManufacturingItemStatus,
  UserStatus,
} from "shared/constants";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import useProductTable from "./hooks/useProductTable";
import ProductHeader from "./ProductHeader";
import ProductSearch from "./ProductSearch";
import ProductSupplierSearch from "./ProductSupplierSearch";

const ProductTable = ({ productsRead, selectedKpi, setSelectedKpi }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [contactsData, setContacts] = useState(null);
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState(false);
  const [showActionBanner, setShowActionBanner] = useState(false);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { data: brands } = useSelector(selectBrandsData);
  const { productBrandId } = useSelector(selectProductDetailsData);
  const { supplierId, supplierName } = useSelector(selectSupplierDetails);
  const products = useSelector(selectProducts);
  const supplierProducts = useSelector(selectSupplierProducts);
  const companiesList = useSelector(selectCompaniesMapForTable);
  const skipAndTakeQueryStats = useSelector(
    selectProductsSkipAndTakeQueryStats
  );
  const isRetailer = useSelector(selectIsRetailer);

  const isProductTableLoading = useSelector(selectIsProductTableLoading);
  const { isLoading: isTradeItemStatusesLoading } = useSelector(
    selectTradeItemStatusesData
  );

  const { isLoading: isNetContentMeasuresLoading } = useSelector(
    selectNetContentMeasuresData
  );
  const isLoading =
    productsRead &&
    (isProductTableLoading ||
      isTradeItemStatusesLoading ||
      isNetContentMeasuresLoading);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage: productsRead ? TablePaginationStorage.PRODUCT : undefined,
    });

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<ProductFilter>(
    {
      brandIds: undefined,
      brandLabels: undefined,
      associatedCompanyLabels: null,
      productCategory: undefined,
      HasNoBrand: false,
      HasNoGtin: false,
      HasNoProductCategory: false,
      tradeItemStatuses: [TradeItemStatus.ACTIVE],
      associatedCompanyIds: null,
      checked: false,
      responsibilityId: "",
      userId: "",
      userName: "",
      retailerCompanyId: null,
      manufacturedItemStatuses: [ManufacturingItemStatus.ACTIVE],
    },
    {
      clearedFilters: {
        checked: false,
        brandIds: undefined,
        brandLabels: undefined,
        associatedCompanyLabels: null,
        HasNoBrand: false,
        HasNoGtin: false,
        HasNoProductCategory: false,
        productCategory: undefined,
        tradeItemStatuses: [],
        associatedCompanyIds: null,
        responsibilityId: "",
        userId: "",
        userName: "",
        retailerCompanyId: null,
        manufacturedItemStatuses: [],
      },
      resetPageNumber,
      setSelectedRowKeys,
      setAllSelected,
      webStorage: productsRead ? SearchFiltersStorage.PRODUCT : undefined,
    }
  );

  const refetchProducts = useCallback(() => {
    const { skip, take } = paginationQuery;
    const {
      brandIds,
      tradeItemStatuses,
      productCategory,
      associatedCompanyIds,
      HasNoBrand,
      HasNoGtin,
      HasNoProductCategory,
      checked,
      userId,
      responsibilityId,
      retailerCompanyId,
      manufacturedItemStatuses,
    } = filterObj;

    const newBrandIds = productBrandId ? [productBrandId] : brandIds;
    const newAssociatedCompanyIds = supplierId
      ? [supplierId]
      : associatedCompanyIds;
    const newTradeItemStatuses = productBrandId
      ? [TradeItemStatus.ACTIVE]
      : tradeItemStatuses;
    const { categoryId, categoryItemId } = productBrandId
      ? { categoryId: undefined, categoryItemId: undefined }
      : productCategory ?? {};
    isRetailer
      ? dispatch(
          fetchProducts({
            searchText,
            tradeItemStatuses: newTradeItemStatuses,
            brandIds: newBrandIds,
            HasNoBrand,
            HasNoGtin,
            HasNoProductCategory,
            categoryId,
            categoryItemId,
            ownerCompanyId,
            skip,
            take,
            associatedCompanyIds: newAssociatedCompanyIds,
            OnlyWithoutContacts: checked ? checked : false,
            ContactUserId: userId,
            ContactResponsibilityId: responsibilityId,
          })
        )
      : dispatch(
          fetchManufacturedItemsByFilters({
            searchText,
            ownerCompanyId,
            skip,
            take,
            retailerCompanyId,
            manufacturedItemStatuses,
          })
        );
  }, [searchText, filterObj, paginationQuery, ownerCompanyId, dispatch]);

  const getArchiveAllProducts = async () => {
    const { skip } = paginationQuery;
    const {
      brandIds,
      tradeItemStatuses,
      productCategory,
      associatedCompanyIds,
      checked,
      userId,
      responsibilityId,
    } = filterObj;

    const newBrandIds = productBrandId ? [productBrandId] : brandIds;
    const newAssociatedCompanyIds = supplierId
      ? [supplierId]
      : associatedCompanyIds;
    const newTradeItemStatuses = productBrandId
      ? [TradeItemStatus.ACTIVE]
      : tradeItemStatuses;
    const { categoryId, categoryItemId } = productBrandId
      ? { categoryId: undefined, categoryItemId: undefined }
      : productCategory ?? {};
    let filter = {};
    if (userId) {
      filter = { responsibilityId, userId };
    }

    const { data } = await PmdAPI.getTradeItemsByFilters(
      {
        searchText,
        tradeItemStatuses: newTradeItemStatuses,
        brandIds: newBrandIds,
        supplierCompanyIds: newAssociatedCompanyIds,
      },
      {
        OnlyWithoutContacts: checked ? checked : false,
        ...filter,
        categoryId,
        categoryItemId,
        ownerCompanyId,
        skip,
        take: 100,
      }
    );
    return data.tradeItems.map(item => item.id);
  };

  useEffect(() => {
    const brandId = productBrandId;
    const associatedCompanyId = supplierId;
    const associatedCompanyLabel = supplierName;
    const tradeItemStatuses = [TradeItemStatus.ACTIVE];
    const productCategory = undefined;
    if (productBrandId || supplierId) {
      mergeFilters({
        brandIds: brandId ? [brandId] : undefined,
        brandLabels: undefined,
        associatedCompanyIds: associatedCompanyId
          ? [associatedCompanyId]
          : null,
        associatedCompanyLabels: associatedCompanyLabel
          ? [associatedCompanyLabel]
          : null,
        tradeItemStatuses,
        productCategory,
        checked: false,
        HasNoBrand: false,
        HasNoGtin: false,
        HasNoProductCategory: false,
        responsibilityId: "",
        userId: "",
        userName: "",
        retailerCompanyId: null,
        manufacturedItemStatuses: [],
      });
      dispatch(setProductBrandId(""));
      dispatch(setSupplierId(null));
      dispatch(setSupplierName(null));
    }

    return () => {
      dispatch(clearProducts(null));
    };
  }, []);

  useEffect(() => {
    if (productsRead) {
      refetchProducts();
    }
  }, [refetchProducts, productsRead]);

  useEffect(() => {
    if (!productsRead) {
      dispatch(clearProducts(null));
      removeAllFiltersAndResetPageNumber();
    }
  }, [productsRead]);

  useEffect(() => {
    dispatch(
      fetchRelatedCompanies({
        companyId: ownerCompanyId,
        isCompanyRelationStatusEnabled: true,
        isPaginate: true,
      })
    );
    dispatch(fetchCompaniesSupplier({ ownerCompanyId }));
    dispatch(fetchAllCompaniesSupplier({ ownerCompanyId }));
  }, [ownerCompanyId]);

  useEffect(() => {
    if (!!selectedRowKeys.length || allSelected) setShowActionBanner(true);
    else setShowActionBanner(false);
    if (
      !!selectedRowKeys.length &&
      selectedRowKeys.length === skipAndTakeQueryStats?.totalCount
    ) {
      setAllSelected(prev => !prev);
      setSelectedRowKeys([]);
    }
  }, [selectedRowKeys, allSelected, skipAndTakeQueryStats]);

  useEffect(() => {
    if (selectedKpi) {
      removeAllFiltersAndResetPageNumber();
      setSearchText("");
      switch (selectedKpi) {
        case "NoBrandCount":
          mergeFilters({
            HasNoBrand: true,
            tradeItemStatuses: [TradeItemStatus.ACTIVE],
          });
          break;
        case "NoCategoryCount":
          mergeFilters({
            HasNoProductCategory: true,
            tradeItemStatuses: [TradeItemStatus.ACTIVE],
          });
          break;
        case "NoContactCount":
          mergeFilters({
            checked: true,
            tradeItemStatuses: [TradeItemStatus.ACTIVE],
          });
          break;
        case "NoGtinKpi":
          mergeFilters({
            HasNoGtin: true,
            tradeItemStatuses: [TradeItemStatus.ACTIVE],
          });
      }
    }
  }, [selectedKpi]);

  const handleContactModel = async rawProductData => {
    const contacts = await getUsersCollectionMap(
      rawProductData?.contacts?.map(c => c.userId)
    );
    let newContacts = [];
    rawProductData?.contacts?.forEach(p => {
      newContacts.push({
        ...newContacts,
        ...contacts[p.userId],
        responsibilityId: p.responsibilityId,
      });
    });

    setContacts({
      itemName: rawProductData.itemName,
      contacts: newContacts,
    });
    setOpenModel(true);
  };
  const { columns, data, supplierColumns, supplierData } = useProductTable({
    products,
    manufacturedItems: supplierProducts,
    companiesList,
    brands,
    refetchProducts,
    handleContactModel,
  });

  const contactColumn: ColumnsType<ContactColumns> = [
    {
      title: intl.formatMessage({ id: "productDetails.contactNameText" }),
      dataIndex: "contactName",
      render: contactName => {
        return <>{contactName ? contactName : "-"}</>;
      },
    },
    {
      title: intl.formatMessage({ id: "productListPage.modal.table.header" }),
      dataIndex: "responsibility",
    },
    {
      title: intl.formatMessage({ id: "general.status" }),
      dataIndex: "status",

      render: record => {
        let lable = intl.formatMessage({ id: "general.inactive" });
        let color = "grey";
        if (record === UserStatus.ENABLED) {
          lable = intl.formatMessage({ id: "general.active" });
          color = "green";
        }

        return (
          <>
            <Tag label={lable} color={color} mode="light" />
          </>
        );
      },
    },
  ];
  return (
    <>
      {productsRead &&
        (isRetailer ? (
          <ProductSearch
            filterObj={filterObj}
            setSelectedKpi={setSelectedKpi}
            mergeFilters={mergeFilters}
            initialSearchValue={searchText}
            onSearch={setSearchText}
            onClearFiltersClick={removeAllFiltersAndResetPageNumber}
          />
        ) : (
          <ProductSupplierSearch
            filterObj={filterObj}
            mergeFilters={mergeFilters}
            initialSearchValue={searchText}
            onSearch={setSearchText}
            onClearFiltersClick={removeAllFiltersAndResetPageNumber}
          />
        ))}
      <ProductHeader
        listingResult={
          <Heading color="grey-5" size="xxs">
            {intl.formatMessage(
              { id: "productsListPage.table.listingResultNumber" },
              {
                current: skipAndTakeQueryStats.currentCount ?? 0,
                total: skipAndTakeQueryStats.totalCount ?? 0,
              }
            )}{" "}
          </Heading>
        }
        searchText={searchText}
        filterObj={filterObj}
        isRetailer={isRetailer}
        selectedRowKeys={selectedRowKeys}
        ownerCompanyId={ownerCompanyId}
        refetchProducts={refetchProducts}
        isAllSelected={allSelected}
        setAllSelected={setAllSelected}
        setSelectedRowKeys={setSelectedRowKeys}
        getArchiveAllProducts={getArchiveAllProducts}
        totalCount={skipAndTakeQueryStats.totalCount}
      />
      {showActionBanner && (
        <ActionBanner
          message={
            allSelected
              ? intl.formatMessage(
                  { id: "productsListPage.table.allSelected" },
                  {
                    selectedCount: skipAndTakeQueryStats.totalCount ?? 0,
                  }
                )
              : intl.formatMessage(
                  {
                    id:
                      selectedRowKeys.length === 1
                        ? "productsListPage.table.oneSelected"
                        : "productsListPage.table.selectedProducts",
                  },
                  { selectedCount: selectedRowKeys.length }
                )
          }
          data-test-id="selectedProducts-banner"
          link={{
            name: allSelected
              ? intl.formatMessage({ id: "productsListPage.table.clearAll" })
              : intl.formatMessage(
                  { id: "productsListPage.table.selectAllProducts" },
                  { allCount: skipAndTakeQueryStats.totalCount ?? 0 }
                ),
            onClick: () => {
              setAllSelected(prev => !prev);
              setSelectedRowKeys([]);
            },
          }}
          type="info"
          closable={!allSelected}
          showIcon={true}
          onClose={() => setShowActionBanner(false)}
        />
      )}
      {isRetailer ? (
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          skip={paginationQuery.skip}
          take={paginationQuery.take}
          skipAndTakeQueryStats={skipAndTakeQueryStats}
          setPaginationQuery={setPaginationQuery}
          rowSelection={{ selectedRowKeys }}
          setSelectedRowKeys={setSelectedRowKeys}
          isAllSelected={allSelected}
          showActionBanner={showActionBanner}
        />
      ) : (
        <Table
          columns={supplierColumns}
          dataSource={supplierData}
          loading={isLoading}
          skip={paginationQuery.skip}
          take={paginationQuery.take}
          skipAndTakeQueryStats={skipAndTakeQueryStats}
          setPaginationQuery={setPaginationQuery}
          rowSelection={{ selectedRowKeys }}
          setSelectedRowKeys={setSelectedRowKeys}
          isAllSelected={allSelected}
          showActionBanner={showActionBanner}
        />
      )}
      <ContactsModal
        open={openModel}
        title={"productsListPage.table.productName"}
        contactsData={contactsData}
        contactColumn={contactColumn}
        onCancel={() => {
          setOpenModel(false);
          setContacts(null);
        }}
        onPrimaryButtonClick={() => {
          setOpenModel(false);
          setContacts(null);
        }}
      />
    </>
  );
};

ProductTable.propTypes = {
  productsRead: PropTypes.bool.isRequired,
};

export default memo(ProductTable);
