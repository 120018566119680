import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Paragraph, Modal } from "@trace-one/design-system";
import { Spinner } from "@trace-one/react-components";
import { Menu } from "antd";

import { CumdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { CompanyStatus, CompanyType } from "shared/constants";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useToast from "shared/hooks/useToast";

import { SupplierColumn } from "../models";

interface SupplierActionProps {
  record: SupplierColumn;
  refetchSuppliers: () => void;
}

const SupplierAction: React.FC<SupplierActionProps> = ({
  record,
  refetchSuppliers,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const toast = useToast();

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const { companyRelationsWrite, companiesRead, companyprivateWrite } =
    usePermissions();
  const [handleExportSupplier, isExportSupplierLoading] = useAsyncFileDownload(
    () => {
      return CumdAPI.exportCompanies(
        { companyIds: [record.key] },
        { relatedOwnerCompanyId: ownerCompanyId }
      );
    },
    { fileExtension: "csv" }
  );

  const confirmArchive = async () => {
    try {
      await CumdAPI.delistCompanyStatusById(record.key);
      toast.success({
        message: intl.formatMessage({
          id: "supplierListPage.table.disabledTitle",
        }),
        description: intl.formatMessage(
          {
            id: "supplierListPage.table.disabledText",
          },
          { CompanyName: record?.companyDisplayName[0] }
        ),
      });
      setShowArchiveModal(false);
      refetchSuppliers();
    } catch (error) {
      toast.error({
        message: intl.formatMessage({
          id: "supplierListPage.table.disabledErrorTitle",
        }),
        description: intl.formatMessage(
          {
            id: "supplierListPage.table.disabledErrorText",
          },
          { CompanyName: record.companyDisplayName[0] }
        ),
      });
      setShowArchiveModal(false);
    }
  };

  return (
    <>
      <ActionDropdown
        data-test-id="md-supplier-action"
        overlay={
          <Menu>
            {companyRelationsWrite && (
              <Menu.Item
                key="supplier-edit"
                onClick={() => {
                  history.push(`/suppliers/edit/${record.key}`);
                }}
                data-test-id="md-supplier-edit"
              >
                {intl.formatMessage({
                  id: "general.editSupplier",
                })}
              </Menu.Item>
            )}
            {companiesRead && (
              <Menu.Item
                key="supplier-export"
                onClick={handleExportSupplier}
                data-test-id="md-supplier-export"
              >
                <Spinner spinning={isExportSupplierLoading}>
                  {intl.formatMessage({
                    id: "general.exportSupplier",
                  })}
                </Spinner>
              </Menu.Item>
            )}
            {record?.companyType === CompanyType.PRIVATE &&
              companyprivateWrite &&
              record?.companyStatus === CompanyStatus.ENABLED && (
                <Menu.Item
                  key="supplier-delete"
                  onClick={() => setShowArchiveModal(true)}
                  data-test-id="md-supplier-delete"
                >
                  {intl.formatMessage({
                    id: "supplierListPage.table.disablePrivate",
                  })}
                </Menu.Item>
              )}
          </Menu>
        }
      />

      <Modal.Simple
        title={intl.formatMessage({ id: "general.information" })}
        size="m"
        visible={showArchiveModal}
        primaryButtonText={intl.formatMessage({ id: "general.confirm" })}
        secondaryButtonText={intl.formatMessage({ id: "general.cancel" })}
        illustrationName="non-conformity"
        onPrimaryButtonClick={confirmArchive}
        onSecondaryButtonClick={() => setShowArchiveModal(false)}
        onCancel={() => setShowArchiveModal(false)}
      >
        <Paragraph size="m">
          {intl.formatMessage(
            {
              id: "supplierListPage.table.confirmDisable",
            },
            { CompanyName: record?.companyDisplayName[0] }
          )}
        </Paragraph>
      </Modal.Simple>
    </>
  );
};

export default SupplierAction;
