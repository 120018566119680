import { createSelector } from "@reduxjs/toolkit";

import { CompanyData } from "models";

import { RootState } from "reduxStore";

export const _selectProductList = (state: RootState) => state.productList;

export const selectProducts = createSelector(
  _selectProductList,
  ({ products }) => products
);
export const selectSupplierProducts = createSelector(
  _selectProductList,
  ({ manufacturedItems }) => manufacturedItems
);

export const selectCompaniesMapForTable = createSelector(
  _selectProductList,
  ({ companies }) => {
    const result: { [companyId: string]: CompanyData } = {};

    companies.forEach(company => (result[company.companyId] = company));
    return result;
  }
);

export const selectProductsSkipAndTakeQueryStats = createSelector(
  _selectProductList,
  ({ productsSkipAndTakeQueryStats }) => productsSkipAndTakeQueryStats
);
export const selectIsProductTableLoading = createSelector(
  _selectProductList,
  ({ isProductsLoading }) => isProductsLoading
);
export const selectIsExportSelectedProductsLoading = createSelector(
  _selectProductList,
  ({ isExportSelectedProductsLoading }) => isExportSelectedProductsLoading
);
export const selectIsExportSelectedManufacturedItemsLoading = createSelector(
  _selectProductList,
  ({ isExportSelectedManufacturedItemsLoading }) =>
    isExportSelectedManufacturedItemsLoading
);
export const selectProductKpiData = createSelector(
  _selectProductList,
  ({ kpiData }) => kpiData
);
export const selectIsKpiDataLoading = createSelector(
  _selectProductList,
  ({ isKpiDataLoading }) => isKpiDataLoading
);
