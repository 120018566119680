import { Row, Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import TabPage from "components/TabPage";

import BannerDetails from "../BannerDetails";

import ProductIdentification from "./ProductIdentification";

interface ProductInformationTabProps {}
const ProductInformationTab: React.FC<ProductInformationTabProps> = () => {
  return (
    <TabPage.Wrapper>
      <Row gutter={[32, 32]}>
        <Col {...PageCol.FULL}>
          <BannerDetails />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <ProductIdentification />
      </Row>
    </TabPage.Wrapper>
  );
};

export default ProductInformationTab;
