import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import {
  Heading,
  TagV1 as Tag,
  Modal,
  Paragraph,
} from "@trace-one/design-system";

import { CumdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchDocumentsListGlobalSearch } from "reduxStore/documentList/asyncActions";
import { selectIsDocumentTableLoading } from "reduxStore/documentList/selectors";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { fetchSupplierDetails } from "reduxStore/SupplierDetails/asyncActions";
import {
  selectSupplierDetails,
  selectSupplierDetailsInitiated,
} from "reduxStore/SupplierDetails/selectors";
import { clearSupplierDetails } from "reduxStore/SupplierDetails/slice";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import {
  CompanyStatus,
  TeamMemberResponsibilityParentItem,
  CompanyType,
} from "shared/constants";
import {
  BaseHocProps,
  withCountries,
  withTeamMemberResponsibilities,
  withCompanyIdentifiers,
} from "shared/hocs";
import useToast from "shared/hooks/useToast";

import { SectionsIds } from "../SupplierEdit/SupplierForm/constants";

import CommentsTab from "./CommentsTab";
import CompanyInformationTab from "./CompanyInformationTab";

interface SupplierDetailsProps extends BaseHocProps {
  isReadOnly?: boolean;
}

const enhance = compose<React.FC<SupplierDetailsProps>>(
  withCountries(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  }),
  withCompanyIdentifiers()
);

const SupplierDetails: React.FC<SupplierDetailsProps> = ({ isInitiated }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const toast = useToast();
  const { supplierId } = useParams<{ supplierId?: string }>();

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const languageCode = useSelector(selectUserLanguageCode);
  const isDocumentListLoading = useSelector(selectIsDocumentTableLoading);
  const { companyRelationsWrite, companyprivateWrite, documentRead } =
    usePermissions();

  const { company, companyRelation, hasError, errorStatus } = useSelector(
    selectSupplierDetails
  );
  const suppplierDetailsLoaded = useSelector(selectSupplierDetailsInitiated);
  // const hasComments = !!companyRelation?.comments?.length;
  const isRetailer = useSelector(selectIsRetailer);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      fetchSupplierDetails({ supplierId, ownerCompanyId, languageCode })
    ).then(() => {
      if (documentRead) {
        dispatch(
          fetchDocumentsListGlobalSearch({
            params: {
              languageCode,
            },
            body: {
              ownerCompanyId: supplierId,
            },
          })
        );
      }
    });
    return () => {
      dispatch(clearSupplierDetails());
    };
  }, [supplierId, ownerCompanyId]);

  const confirmArchive = async () => {
    try {
      await CumdAPI.delistCompanyStatusById(company.companyId);
      toast.success({
        message: intl.formatMessage({
          id: "supplierListPage.table.disabledTitle",
        }),
        description: intl.formatMessage(
          {
            id: "supplierListPage.table.disabledText",
          },
          { CompanyName: company?.companyDisplayName }
        ),
      });
      setShowArchiveModal(false);
      dispatch(clearSupplierDetails());
      dispatch(
        fetchSupplierDetails({ supplierId, ownerCompanyId, languageCode })
      );
    } catch (error) {
      toast.error({
        message: intl.formatMessage({
          id: "supplierListPage.table.disabledErrorTitle",
        }),
        description: intl.formatMessage(
          {
            id: "supplierListPage.table.disabledErrorText",
          },
          { CompanyName: company.companyDisplayName }
        ),
      });
      setShowArchiveModal(false);
    }
  };

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "suplierDetails.companyInformation" })}{" "}
        </Heading>
      ),
      content: <CompanyInformationTab />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.comments" })}{" "}
        </Heading>
      ),
      content: <CommentsTab />,
    },
  ];

  const buttonList = [
    {
      text: "general.actions",
      showBtn: isRetailer && companyRelationsWrite,
      testId: "supplier-actions",
      tab: 0,
      actionList: [
        {
          name: "general.edit",
          "data-test-id": "editSupplier-button",
          disabled: false,
          onClick: () => history.push(`/suppliers/edit/${company.companyId}`),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: "general.addComment",
          "data-test-id": "editSupplierComments-button",
          disabled: false,
          onClick: () =>
            history.push(
              `/suppliers/edit/${company.companyId}#${SectionsIds.COMMENTS}`
            ),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: "supplierListPage.table.disablePrivate",
          "data-test-id": "disablePrivateCompany-button",
          disabled: false,
          onClick: () => setShowArchiveModal(true),
          showTooltip: false,
          showBtn:
            company?.companyType === CompanyType.PRIVATE &&
            companyprivateWrite &&
            company?.companyStatus === CompanyStatus.ENABLED,
        },
      ],
    },
  ];

  if (hasError) return <ErrorPage status={errorStatus} />;
  if (!isInitiated || !suppplierDetailsLoaded || isDocumentListLoading)
    return <Spinner underHeader />;

  const HeaderTitle = () => {
    let title = !!companyRelation.externalName
      ? companyRelation.externalName
      : company.companyDisplayName;

    if (companyRelation.externalIdentifier) {
      title = `${title} (${companyRelation.externalIdentifier})`;
    }

    return <>{title}</>;
  };

  const HeaderSubtitle = () => {
    return <></>;
  };

  const CompanyStatusTag = () => {
    if (company.companyStatus === CompanyStatus.ENABLED) {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.active" })}
          color="green"
          mode="light"
        />
      );
    }
    if (
      company.companyStatus === CompanyStatus.SUSPENDED ||
      company.companyStatus === CompanyStatus.DISABLED
    ) {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.inactive" })}
          color="grey"
          mode="light"
        />
      );
    }
    return null;
  };

  return (
    <div>
      <HeaderDetails
        withBackNav
        title={HeaderTitle()}
        subtitle={<HeaderSubtitle />}
        status={<CompanyStatusTag />}
        tabs={tabList}
        headerIcon="supplier"
        buttons={buttonList}
        haveActionItems
      />

      <Modal.Simple
        title={intl.formatMessage({ id: "general.information" })}
        size="m"
        visible={showArchiveModal}
        primaryButtonText={intl.formatMessage({ id: "general.confirm" })}
        secondaryButtonText={intl.formatMessage({ id: "general.cancel" })}
        illustrationName="non-conformity"
        onPrimaryButtonClick={confirmArchive}
        onSecondaryButtonClick={() => setShowArchiveModal(false)}
        onCancel={() => setShowArchiveModal(false)}
      >
        <Paragraph size="m">
          {intl.formatMessage(
            {
              id: "supplierListPage.table.confirmDisable",
            },
            { CompanyName: company?.companyDisplayName }
          )}
        </Paragraph>
      </Modal.Simple>
    </div>
  );
};

export default enhance(SupplierDetails);
