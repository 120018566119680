import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { UploadFiles } from "@trace-one/business-components";
import { Heading, Button, Tooltip } from "@trace-one/design-system";

import { DmsAPI } from "apis";

import useToast from "shared/hooks/useToast";
import { downloadBlobAsFile } from "shared/utils/downloadBlobAsFile";

import styles from "./FileSelection.module.less";

const FileSelection = ({
  setSelectedRowKeys,
  selectedDocuments,
  setIsFilesSelectionVisible,
}) => {
  const { formatMessage } = useIntl();
  const [filesLength, setFilesLength] = useState<number>();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const canDownload = filesLength < 2000;
  const toast = useToast();

  useEffect(() => {
    const totalLenth = selectedDocuments.reduce((total, i) => {
      var sizeInMB = (i.fileLength / (1024 * 1024)).toFixed(2);
      return total + parseFloat(sizeInMB);
    }, 0);
    setFilesLength(totalLenth);
  }, [selectedDocuments]);

  const downloadDocuments = async () => {
    setIsDownloading(true);
    const documentsData = selectedDocuments.map(doc => ({
      fileId: doc.fileId,
      fileVersionId: doc.versionId,
    }));
    try {
      const { data } = await DmsAPI.downloadDocuments(
        documentsData,
        {
          zipName: formatMessage({
            id: "general.documentLibrary",
          }),
        },
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([data], { type: "application/zip" });
      downloadBlobAsFile(
        blob,
        formatMessage({
          id: "general.documentLibrary",
        })
      );
      toast.success({
        message: formatMessage({
          id: "documentLibraryListPage.docSelection.downloadSuccessTitle",
        }),
        description: formatMessage({
          id: "documentLibraryListPage.docSelection.downloadSuccessDescription",
        }),
      });
      setIsDownloading(false);
    } catch (_) {
      toast.fetchError({
        checkErrorResponse: false,
      });
      setIsDownloading(false);
    }
  };

  return (
    <div
      data-test-id="md-fileSelectionContainer"
      className={styles.fileSelectionContainer}
    >
      <div
        className={`${styles.headerSection} py-1 d-flex justify-content-between align-content-center`}
      >
        <Heading size="xxs">
          {formatMessage(
            {
              id: "documentLibraryListPage.docSelection.title",
            },
            {
              count: selectedDocuments.length,
            }
          )}
        </Heading>

        <Button
          data-test-id="file-selection-closing-icon"
          type="tertiary"
          color="grey"
          iconName="close"
          onClick={() => setIsFilesSelectionVisible(false)}
        />
      </div>
      <div className={styles.fileListContainer}>
        {selectedDocuments.map(doc => {
          const { fileId, fileName, fileLength } = doc;
          return (
            <div key={fileId} className={styles.fileItem}>
              <UploadFiles.Item
                fileId={fileId}
                fileSize={fileLength}
                showFileSize
                {...(fileLength && { showFileDescription: true })}
                fileName={fileName}
                getTooltipContainer={triggerNode => triggerNode}
                showRemoveTooltip={false}
                onRemove={() => {
                  setSelectedRowKeys(prev => prev.filter(x => x !== fileId));
                }}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.footerSection}>
        <Button
          onClick={() => {
            setSelectedRowKeys([]);
          }}
          type="secondary"
          data-test-id="file-selection-closing-btn"
        >
          {formatMessage({
            id: "general.cancel",
          })}
        </Button>
        <Tooltip
          onOpenChange={() => setTooltipVisible(!tooltipVisible)}
          placement="top"
          text={formatMessage({
            id: "documentLibraryListPage.docSelection.downloadDisabled.toolTipTitle",
          })}
          visible={!canDownload && tooltipVisible}
        >
          <div>
            <Button
              type="primary"
              disabled={!canDownload}
              data-test-id="file-selection-download-btn"
              loading={isDownloading}
              onClick={downloadDocuments}
            >
              {formatMessage({ id: "general.download" })}
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default FileSelection;
