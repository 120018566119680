import { useState, useEffect, MutableRefObject } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { FileItem } from "@trace-one/api-clients.global-search";
import {
  Paragraph,
  Illustration,
  Button,
  Tooltip,
} from "@trace-one/design-system";

import { GlobalSearchAPI, PmdAPI } from "../../../../../../../apis";
import {
  selectUserApplications,
  selectUserLanguageCode,
} from "../../../../../../../reduxStore/user/selectors";
import { ApplicationTypes } from "../../../../../../../shared/constants";
import { GetUserResut } from "../../../../../../../shared/utils/getUsersCollectionMap";
import styles from "../LinkedItems/LinkedItems.module.less";

type LinkedItemType =
  | FileItem["sites"][number]
  | FileItem["projects"][number]
  | FileItem["specs"][number]
  | { tradeItemId: string; gtin: string };

interface Props {
  fileId?: string;
  uploadedUserInfoRef?: MutableRefObject<GetUserResut>;
}

const GSSLinkedItems = ({ fileId, uploadedUserInfoRef }: Props) => {
  const intl = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const userApplications = useSelector(selectUserApplications);

  const [isLinksDataFetchingCompleted, setIsLinksDataFetchingCompleted] =
    useState(false);

  const hasAccessToProjectApp = userApplications.some(
    obj => obj.applicationTypeId === ApplicationTypes.PROJECT
  );
  const hasAccessToSpecApp = userApplications.some(
    obj => obj.applicationTypeId === ApplicationTypes.SPEC
  );

  const uploadedUser = uploadedUserInfoRef?.current;

  const [fileData, setFileData] = useState<FileItem>();
  const [specsData, setSpecsData] = useState<any[]>([]);
  const [tradeItemsData, setTradeItemsData] = useState<any[]>([]);

  const hasTradeItems = fileData?.projects?.some(
    project => project.tradeItems.length > 0
  );

  let ownerCompanyName = "";
  if (uploadedUser?.owningCompanyId === fileData?.ownerCompanyId) {
    ownerCompanyName = uploadedUser?.owningCompanyName;
  }
  const fetchFileData = async (fileId: string) => {
    if (!fileId) {
      return;
    }
    setIsLinksDataFetchingCompleted(false);

    const { data } = await GlobalSearchAPI.getFileById(fileId, {
      languageCode: languageCode,
    });

    setFileData(data);
    setIsLinksDataFetchingCompleted(true);
  };

  const fetchSpecsData = async () => {
    if (!fileData?.specs) {
      return;
    }
    setIsLinksDataFetchingCompleted(false);

    const specsPromises = fileData.specs.map(async spec => {
      const { tradeItemId, gtin } = spec.tradeItem;
      const tradeItemDetails = await PmdAPI.getTradeItemById(tradeItemId);
      return {
        tradeItemId,
        gtin,
        itemName: tradeItemDetails.data.itemName,
      };
    });

    const specsData = await Promise.all(specsPromises);
    setSpecsData(specsData);
    setIsLinksDataFetchingCompleted(true);
  };

  const fetchTradeItemsData = async () => {
    if (!fileData?.projects) {
      return;
    }
    setIsLinksDataFetchingCompleted(false);

    const tradeItemsPromises = fileData.projects.flatMap(project =>
      project.tradeItems.map(async tradeItem => {
        const { tradeItemId, gtin } = tradeItem;
        const tradeItemDetails = await PmdAPI.getTradeItemById(tradeItemId);
        return {
          tradeItemId,
          gtin,
          itemName: tradeItemDetails.data.itemName,
        };
      })
    );

    const tradeItemsData = await Promise.all(tradeItemsPromises);
    setTradeItemsData(tradeItemsData);
    setIsLinksDataFetchingCompleted(true);
  };

  useEffect(() => {
    fetchFileData(fileId);
    fetchSpecsData();
  }, [fileId]);

  useEffect(() => {
    if (fileData && fileData?.projects) {
      fetchTradeItemsData();
    }
  }, [fileData]);

  const Items = ({
    title,
    linkedItems,
    newTab,
  }: {
    title: string;
    linkedItems: LinkedItemType[];
    newTab?: boolean;
  }) => {
    const [isItemsVisible, setIsItemsVisible] = useState<boolean>(true);
    return (
      <div className={styles.linkedItems}>
        <Paragraph
          onClick={() => setIsItemsVisible(prev => !prev)}
          className={styles.linkedItemTitle}
        >
          {title}
          <Button
            type="tertiary"
            data-test-id="md-documents-linkeditems-icon"
            iconOnly
            iconName={isItemsVisible ? "arrow-up" : "arrow-down"}
            className={styles.arrowIcon}
            size="small"
          />
        </Paragraph>
        {isItemsVisible && (
          <div className={styles.linkedItems}>
            {linkedItems?.map((item, index) => {
              return (
                <Tooltip
                  key={index}
                  showFullText
                  text={getTitle(item)}
                  placement="left"
                >
                  <Button
                    type="link"
                    size="small"
                    linkColor="grey-5"
                    className={styles.linkedItemLink}
                    href={getHref(item)}
                    target={newTab ? "_self" : undefined}
                  >
                    {getContent(item)}
                  </Button>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const getContent = (item: LinkedItemType): string => {
    if ("siteName" in item) {
      const siteItem = item;
      return `${siteItem.siteName} - ${ownerCompanyName && ownerCompanyName}`;
    } else if ("projectId" in item) {
      const projectItem = item;
      return projectItem.name;
    } else if ("specificationId" in item) {
      const specItem = item;
      const foundSpec = specsData.find(
        spec => spec.tradeItemId === specItem.tradeItem.tradeItemId
      );
      return `${foundSpec?.itemName} - ${specItem.tradeItem.gtin}`;
    } else if ("tradeItemId" in item) {
      const tradeItem = item as { tradeItemId: string };
      const foundTradeItem = tradeItemsData.find(
        ti => ti.tradeItemId === tradeItem.tradeItemId
      );
      const itemName = foundTradeItem?.itemName;
      const gtin = foundTradeItem && foundTradeItem.gtin;

      if (gtin !== null) {
        return `${itemName} - ${gtin}`;
      } else {
        return `${itemName}`;
      }
    }
  };

  const getTitle = (item: LinkedItemType): string => {
    if ("siteName" in item) {
      const siteItem = item;
      return siteItem.siteName;
    } else if ("projectId" in item) {
      const projectItem = item;
      return projectItem.name;
    } else if ("specificationId" in item) {
      const specItem = item;
      const foundSpec = specsData.find(
        spec => spec.tradeItemId === specItem.tradeItem.tradeItemId
      );
      return `${foundSpec?.itemName} - ${specItem.tradeItem.gtin}`;
    } else if ("tradeItemId" in item) {
      const tradeItem = item as { tradeItemId: string };
      const foundTradeItem = tradeItemsData.find(
        item => item.tradeItemId === tradeItem.tradeItemId
      );
      const itemName = foundTradeItem?.itemName;
      const gtin = foundTradeItem && foundTradeItem.gtin;
      if (gtin !== null) {
        return `${itemName} - ${gtin}`;
      } else {
        return `${itemName}`;
      }
    }
  };

  const getHref = (item: LinkedItemType): string => {
    if ("siteId" in item) {
      const siteItem = item;
      return `/sites/${siteItem.siteId}`;
    } else if ("projectId" in item) {
      const projectItem = item;

      return `${window.env.PROJECT_UI_URL}/projects/projectfolder/${projectItem.projectId}/tasks?from=${window.env.BASE_URL}/documents/list&appid=100`;
    } else if ("specificationId" in item) {
      const specItem = item;
      return `${window.env.SPEC_URL}specifications/${specItem.specificationId}`;
    } else if ("tradeItemId" in item) {
      const tradeItem = item as { tradeItemId: string };
      return `/products/${tradeItem.tradeItemId}`;
    }
  };

  return (
    <div>
      <div>
        {fileData?.sites === null && fileData?.projects === null && (
          <div className={styles.noLinkSection}>
            <Illustration color="grey" name="no-data" />
            <Paragraph>
              {intl.formatMessage({
                id: "documentLibraryListPage.docDetails.tabs.linkedItems.noLinks",
              })}
            </Paragraph>
          </div>
        )}
        {fileData?.sites !== null && isLinksDataFetchingCompleted && (
          <Items
            title={intl.formatMessage({
              id: "documentLibraryListPage.docDetails.tabs.linkedSites.title",
            })}
            linkedItems={fileData?.sites}
          />
        )}
        {fileData?.projects !== null &&
          hasAccessToProjectApp &&
          isLinksDataFetchingCompleted && (
            <Items
              title={intl.formatMessage({
                id: "documentLibraryListPage.docDetails.tabs.linkedProjects.title",
              })}
              linkedItems={fileData?.projects}
              newTab={true}
            />
          )}

        {fileData?.projects &&
          hasTradeItems &&
          isLinksDataFetchingCompleted && (
            <Items
              title={intl.formatMessage({
                id: "documentLibraryListPage.docDetails.tabs.linkedProducts.title",
              })}
              linkedItems={
                fileData?.projects?.flatMap(
                  project => project.tradeItems
                ) as LinkedItemType[]
              }
              newTab={true}
            />
          )}

        {fileData?.specs !== null &&
          hasAccessToSpecApp &&
          isLinksDataFetchingCompleted && (
            <Items
              title={intl.formatMessage({
                id: "documentLibraryListPage.docDetails.tabs.linkedSpecs.title",
              })}
              linkedItems={fileData?.specs}
              newTab={true}
            />
          )}
      </div>
    </div>
  );
};

export default GSSLinkedItems;
