import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import { PageCol } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectDocuments } from "reduxStore/documentList/selectors";
import { selectUserApplications } from "reduxStore/user/selectors";

import { ApplicationTypes } from "shared/constants";

import CustomAttributes from "./CustomAttributes";
import ProductManagement from "./ProductManagement";
import QuickLinks from "./QuickLinks";
import RecentDocuments from "./RecentDocuments";

const PrivateInformation = () => {
  const userApplications = useSelector(selectUserApplications);
  const pdmIds = [
    ApplicationTypes.PDM,
    ApplicationTypes.PDMFIND,
    ApplicationTypes.PDMNA,
  ];
  const { documentRead } = usePermissions();
  const application = userApplications?.find(app =>
    pdmIds.includes(app.applicationTypeId)
  );
  const documents = useSelector(selectDocuments);

  return (
    <Col {...PageCol.HALF}>
      <Row gutter={[32, 32]}>
        {application && (
          <Col {...PageCol.FULL}>
            <QuickLinks />
          </Col>
        )}
        {!!documents.length && documentRead && (
          <Col {...PageCol.FULL}>
            <RecentDocuments documents={documents} />
          </Col>
        )}
        <Col {...PageCol.FULL}>
          <ProductManagement />
        </Col>
        <Col {...PageCol.FULL}>
          <CustomAttributes />
        </Col>
      </Row>
    </Col>
  );
};

export default PrivateInformation;
