import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { DocumentDetailAPIResponse } from "@trace-one/api-clients.dms";

import { DmsAPI } from "apis";

import { selectUserLanguageCode } from "reduxStore/user/selectors";

import { downloadBlobAsFile } from "shared/utils/downloadBlobAsFile";
import getUsersCollectionMap, {
  GetUserResut,
} from "shared/utils/getUsersCollectionMap";

interface Props {
  listType: "tiles" | "table";
}

export interface signerInfoData {
  userName: string;
  userCompanyName: string;
  signedDate: string;
  signStatus: string;
}

const useFileDetails = ({ listType }: Props) => {
  const languageCode = useSelector(selectUserLanguageCode);
  const [isFileInfoVisible, setIsFileInfoVisible] = useState<boolean>(false);
  const [isFileDetailsLoading, setIsFileDetailsLoading] =
    useState<boolean>(false);
  const fileInfoRef = useRef<DocumentDetailAPIResponse | null>(null);
  const uploadedUserInfoRef = useRef<GetUserResut | null>(null);
  const signatureInfoRef = useRef<signerInfoData[] | null>(null);
  const [thumbnail, setThumbnail] = useState(null);

  const fetchDocumentDetails = async (fileId: string) => {
    if (!fileId) {
      return;
    }

    const { data } = await DmsAPI.getDocumentDetails(fileId, {
      languageCode: languageCode,
    });
    return data;
  };

  const displayFileInfo = async (fileId: string) => {
    setIsFileInfoVisible(true);
    if (fileId !== fileInfoRef?.current?.fileId) {
      setThumbnail(null);
      setIsFileDetailsLoading(true);
      try {
        const data = await fetchDocumentDetails(fileId);
        if (data?.uploadedBy) {
          const users = await getUsersCollectionMap([data.uploadedBy]);
          uploadedUserInfoRef.current = users[data.uploadedBy] || null;
        }
        fileInfoRef.current = data;
        const thumbnailExtensions = window.env["ExtensionsForThumbnails"]
          .split(",")
          .map(ext => ext.trim().toLowerCase());
        const fileName = fileInfoRef.current.fileName;
        var lastIndex = fileName.lastIndexOf(".");
        const fileType = fileName
          .substring(lastIndex + 1)
          .trim()
          .toLowerCase();
        if (thumbnailExtensions.includes(fileType)) {
          const { data: thumbnail } = await DmsAPI.downloadThumbnail(
            fileId,
            {},
            {
              responseType: "blob",
            }
          );
          if (fileId === fileInfoRef?.current?.fileId)
            setThumbnail(window.URL.createObjectURL(thumbnail));
        }
      } catch (error) {
        console.error(error);
      } finally {
        await getSignatureById(fileId);
      }
    }
  };

  const getSignatureById = async (fileId: string) => {
    try {
      const { data } = await DmsAPI.getSignatureById(fileId);
      signatureInfoRef.current = await Promise.all(
        data.signers.map(
          async ({ userId, signatureStatus, signedDateTime }) => {
            const users = await getUsersCollectionMap([userId]);
            return {
              userName: users[userId]?.userName || null,
              userCompanyName: users[userId]?.owningCompanyName || null,
              signedDate: signedDateTime,
              signStatus: signatureStatus,
            };
          }
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsFileDetailsLoading(false);
    }
  };

  const downloadFile = async (
    fileId: string,
    versionId: string,
    fileName: string
  ) => {
    try {
      const result = await DmsAPI.downloadDocument(
        fileId,
        { versionId, fileName } as any,
        {
          responseType: "blob",
        }
      );

      if (!result?.data) {
        return;
      }

      downloadBlobAsFile(result.data, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    isFileInfoVisible,
    setIsFileInfoVisible,
    fileInfoRef,
    uploadedUserInfoRef,
    isFileDetailsLoading,
    displayFileInfo,
    downloadFile,
    signatureInfoRef,
    thumbnail,
  };
};

export default useFileDetails;
