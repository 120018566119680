export function downloadBlobAsFile(file: Blob, name?: string): void {
  const href = URL.createObjectURL(file);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", name ?? "");
  link.click();
  URL.revokeObjectURL(href);

  const fileSizeInMB = Math.floor(file.size / (1024 * 1024));
  const canCauseTabCrashInEdge = fileSizeInMB > 700;

  if (navigator.userAgent.includes("Edg/") && canCauseTabCrashInEdge) {
    window.location.reload();
  }
}
