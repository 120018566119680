import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  fetchProducts,
  fetchKpiIndicators,
  fetchManufacturedItemsByFilters,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "productList",
  initialState,
  reducers: {
    clearProducts: (state, action) => {
      state.products = initialState.products;
      state.manufacturedItems = initialState.manufacturedItems;
      state.companies = initialState.companies;
      state.productsSkipAndTakeQueryStats =
        initialState.productsSkipAndTakeQueryStats;
      state.isProductsLoading = false;
      state.hasProductsError = false;
    },
    clearKpiData: state => {
      state.kpiData = undefined;
      state.isKpiDataLoading = true;
    },
    setIsExportSelectedProductsLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isExportSelectedProductsLoading = action.payload;
    },
    setIsExportSelectedManufacturedItemsLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isExportSelectedManufacturedItemsLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.isProductsLoading = true;
        state.hasProductsError = false;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload.tradeItems;
        state.productsSkipAndTakeQueryStats =
          action.payload.skipAndTakeQueryStats;
        state.isProductsLoading = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.products = [];
        state.isProductsLoading = false;
        state.hasProductsError = true;
        state.productsSkipAndTakeQueryStats =
          initialState.productsSkipAndTakeQueryStats;
      })
      .addCase(fetchManufacturedItemsByFilters.pending, (state, action) => {
        state.isProductsLoading = true;
        state.hasProductsError = false;
      })
      .addCase(fetchManufacturedItemsByFilters.fulfilled, (state, action) => {
        state.manufacturedItems = action.payload.productList.manufacturedItems;
        state.companies = action.payload.companyList;
        state.productsSkipAndTakeQueryStats =
          action.payload.productList.skipAndTakeQueryStats;
        state.isProductsLoading = false;
      })
      .addCase(fetchManufacturedItemsByFilters.rejected, (state, action) => {
        state.manufacturedItems = [];
        state.isProductsLoading = false;
        state.hasProductsError = true;
        state.productsSkipAndTakeQueryStats =
          initialState.productsSkipAndTakeQueryStats;
      })
      .addCase(fetchKpiIndicators.pending, (state, action) => {
        state.isKpiDataLoading = true;
      })
      .addCase(fetchKpiIndicators.fulfilled, (state, action) => {
        state.kpiData = action.payload;
        state.isKpiDataLoading = false;
      })
      .addCase(fetchKpiIndicators.rejected, (state, action) => {
        state.kpiData = undefined;
        state.isKpiDataLoading = false;
      });
  },
});

export const {
  clearProducts,
  setIsExportSelectedProductsLoading,
  setIsExportSelectedManufacturedItemsLoading,
  clearKpiData,
} = slice.actions;
export default slice.reducer;
