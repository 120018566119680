import { memo, useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { Picker } from "@trace-one/design-system";

import { selectRelatedCompanyOptions } from "reduxStore/shared/selectors";

import AsyncCompanySelect from "components/AsyncCompanySelect";
import AsyncPickerDropdown from "components/AsyncPickerDropdown";
import CheckableTags from "components/CheckableTags";
import { SearchFiltersProps } from "components/SearchFilters";
import { ManufacturingItemStatus } from "shared/constants";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { ProductSupplierFilter } from "../models";

import styles from "./ProductSupplierSearch.module.less";

interface ProductSearchProps {
  filterObj: ProductSupplierFilter;
  mergeFilters: (
    value:
      | Partial<ProductSupplierFilter>
      | ((prevState: ProductSupplierFilter) => ProductSupplierFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const ProductSupplierSearch: React.FC<ProductSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const intl = useIntl();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const relatedCompanyOptions = useSelector(selectRelatedCompanyOptions);
  const [associatedCompanyLabel, setAssociatedCompanyLabel] = useState("");
  const showIntercom = useRef(true);

  useEffect(() => {
    const { retailerCompanyId, manufacturedItemStatuses } = filterObj;
    const defaultSelectedFilters = [
      ...(retailerCompanyId
        ? [
            {
              filterId: "1",
              values: [retailerCompanyId],
            },
          ]
        : []),
      ...(manufacturedItemStatuses
        ? [
            {
              filterId: "2",
              values: manufacturedItemStatuses,
            },
          ]
        : []),
    ];
    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: intl.formatMessage({
        id: "general.retailer",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ retailerCompanyId: null });
      },
      elementToRender: (
        <AsyncCompanySelect
          includePrivate={true}
          isSupplierInput={""}
          value={filterObj.retailerCompanyId}
          label={associatedCompanyLabel}
          onChange={retailerCompanyId => {
            mergeFilters({ retailerCompanyId });
          }}
          defaultOptions={relatedCompanyOptions}
          companyActivityId={undefined}
          isRetailer={false}
          onLabelChange={name => {
            setAssociatedCompanyLabel(name);
          }}
          key={associatedCompanyLabel}
          id="md-productFilter-retailerId"
          getPopupContainer={trigger => trigger.parentElement}
          data-test-id="md-productFilter-retailer"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-retailer"
          searchPlaceholderText={intl.formatMessage({ id: "general.search" })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          title={intl.formatMessage({
            id: "general.retailer",
          })}
          showBadgeCount
          searchOnChange
          onSearch={str => {
            return relatedCompanyOptions
              .filter(x => x.name.toLowerCase().includes(str.toLowerCase()))
              .map(c => ({
                label: c.name,
                value: c.value,
              }));
          }}
          defaultOptions={relatedCompanyOptions.map(c => ({
            label: c.name,
            value: c.value,
          }))}
          value={
            filterObj.retailerCompanyId ? [filterObj.retailerCompanyId] : []
          }
          showSearchInput
          selectionType="single"
          minLengthToSearch={1}
          onAsyncSearch={undefined}
          onChange={({ value }) => {
            mergeFilters({
              retailerCompanyId:
                value[0] === filterObj.retailerCompanyId ? undefined : value[0],
            });
          }}
          clearSelection={() => mergeFilters({ retailerCompanyId: null })}
        ></AsyncPickerDropdown>
      ),
      filterId: "1",
    },
    {
      title: intl.formatMessage({
        id: "general.status",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          manufacturedItemStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.manufacturedItemStatuses}
          onChange={manufacturedItemStatuses => {
            mergeFilters({
              manufacturedItemStatuses,
            });
          }}
          options={[
            {
              value: ManufacturingItemStatus.ACTIVE,
              label: intl.formatMessage({ id: "general.notArchived" }),
            },
            {
              value: ManufacturingItemStatus.INACTIVE,
              label: intl.formatMessage({ id: "general.archived" }),
            },
          ]}
        />
      ),
      quickFilter: (
        <Picker.Filter
          data-test-id="picker-filter-status"
          title={intl.formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          items={[
            {
              value: ManufacturingItemStatus.ACTIVE,
              label: intl.formatMessage({ id: "general.notArchived" }),
            },
            {
              value: ManufacturingItemStatus.INACTIVE,
              label: intl.formatMessage({ id: "general.archived" }),
            },
          ]}
          value={filterObj.manufacturedItemStatuses ?? []}
          selectionType="multi"
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          onChange={({ value: manufacturedItemStatuses }) => {
            mergeFilters({
              manufacturedItemStatuses,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              manufacturedItemStatuses: [],
            })
          }
        ></Picker.Filter>
      ),
      filterId: "2",
    },
  ];

  return (
    <div className={styles.SearchFilter}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: intl.formatMessage({
            id: "productsSupplierListPage.searchPlaceholder",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
      />
    </div>
  );
};

export default memo(ProductSupplierSearch);
