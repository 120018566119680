import { createAsyncThunk } from "@reduxjs/toolkit";

import { SmdAPI, CumdAPI } from "apis";
import { CompanyData } from "models";

import { CompanyActivityId, dropdownFilterCount } from "shared/constants";

export const fetchSites = createAsyncThunk(
  "siteList/fetchLists",
  async ({
    ownerCompanyId,
    isRetailer,
    skip,
    take,
    searchText,
    OnlyWithoutContacts,
    ContactUserId,
    ContactResponsibilityId,
    associatedCompanyIds,
    associatedCompanyId,
    SiteWithNoCertificate,
    SiteWithCertificateExpire,
    SiteWithCertificateNoEndDate,
    ...filters
  }: {
    ownerCompanyId: string;
    isRetailer: boolean;
    skip: number;
    take: number;
    searchText: string;
    associatedCompanyIds?: string[];
    associatedCompanyId?: string;
    siteCountry?: string;
    categoryId?: string;
    categoryItemId?: string;
    OnlyWithoutContacts?: boolean;
    ContactUserId?: string;
    ContactResponsibilityId?: string;
    siteActive?: any;
    SiteWithNoCertificate?: boolean;
    SiteWithCertificateExpire?: boolean;
    SiteWithCertificateNoEndDate?: boolean;
  }) => {
    const { data: siteList } = isRetailer
      ? await SmdAPI.getRelationsByFilters(
          {
            ownerCompanyIdCollection: associatedCompanyIds,
          },
          {
            skip,
            take,
            siteCountry: filters.siteCountry,
            categoryId: filters.categoryId,
            categoryItemId: filters.categoryItemId,
            searchText,
            associatedCompanyId: ownerCompanyId,
            ownerCompanyRelationStatus: filters.siteActive,
            onlyWithoutContacts: OnlyWithoutContacts,
            contactResponsibilityId: ContactResponsibilityId,
            contactUserId: ContactUserId,
            hasNoCertificate: SiteWithNoCertificate,
            hasCertificateThatExpireSoon: SiteWithCertificateExpire,
            hasCertificateWithNoEndDate: SiteWithCertificateNoEndDate,
          }
        )
      : await SmdAPI.getSitesByFilters(
          { ownerCompanyIdCollection: [ownerCompanyId] },
          {
            includedSiteRelation: true,
            ownerCompanyRelationStatus: true,
            skip,
            take,
            searchText,
            associatedCompanyId,
            hasNoCertificate: SiteWithNoCertificate,
            hasCertificateThatExpireSoon: SiteWithCertificateExpire,
            hasCertificateWithNoEndDate: SiteWithCertificateNoEndDate,
            ...filters,
          }
        );

    const companyIds = isRetailer
      ? siteList.sites.map(s => s.ownerCompanyId)
      : siteList.sites.reduce<string[]>((acc, s) => {
          const companyIds = s?.siteRelations?.associatedCompanyIds;
          return companyIds ? [...acc, ...companyIds] : acc;
        }, []);

    let companyList: CompanyData[] = [];
    // Because the API will throw an error if companyIds is empty,
    // we have to make this little condition.
    // I'm not very happy with that, but life isn't about waiting for
    // the storm to pass it's about learning to dance in the rain.
    if (companyIds.length) {
      const companyIdsUniq: string[] = [...new Set(companyIds)];
      companyList = await CumdAPI.getCompaniesByFilters(
        { companyIds: companyIdsUniq },
        {
          relatedOwnerCompanyId: isRetailer ? ownerCompanyId : undefined,
          relatedAssociatedCompanyId: isRetailer ? undefined : ownerCompanyId,
          includePrivate: true,
        }
      ).then(({ data }) => data.companies);
    }

    return { siteList, companyList };
  }
);

export const fetchCompaniesSupplier = createAsyncThunk(
  "siteList/fetchCompaniesSupplier",
  async ({ ownerCompanyId }: { ownerCompanyId: string }) => {
    const { data: supplierCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {
        skip: 0,
        take: dropdownFilterCount,
        relatedOwnerCompanyId: ownerCompanyId,
        companyActivityId: CompanyActivityId.SUPPLIER,
        isCompanyRelationStatusEnabled: true,
        includePrivate: true,
      }
    );
    return supplierCompanies.companies;
  }
);

export const fetchKpiIndicators = createAsyncThunk(
  "siteList/fetchKpiIndicators",
  async (
    {
      ownerCompanyId,
      isRetailer,
    }: { ownerCompanyId: string; isRetailer: boolean },
    thunkAPI
  ) => {
    try {
      const [missingCount, expiringCount, noDateCount] = await Promise.all([
        isRetailer
          ? SmdAPI.getRelationsByFilters(
              {
                ownerCompanyIdCollection: null,
              },
              {
                associatedCompanyId: ownerCompanyId,
                hasNoCertificate: true,
                ownerCompanyRelationStatus: true,
                hasCertificateThatExpireSoon: false,
                hasCertificateWithNoEndDate: false,
              }
            ).then(({ data }) => data.skipAndTakeQueryStats.totalCount)
          : SmdAPI.getSitesByFilters(
              { ownerCompanyIdCollection: [ownerCompanyId] },
              {
                includedSiteRelation: true,
                ownerCompanyRelationStatus: true,
                siteActive: true,
                hasNoCertificate: true,
                hasCertificateThatExpireSoon: false,
                hasCertificateWithNoEndDate: false,
              }
            ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
        isRetailer
          ? SmdAPI.getRelationsByFilters(
              {
                ownerCompanyIdCollection: null,
              },
              {
                associatedCompanyId: ownerCompanyId,
                hasNoCertificate: false,
                ownerCompanyRelationStatus: true,
                hasCertificateThatExpireSoon: true,
                hasCertificateWithNoEndDate: false,
              }
            ).then(({ data }) => data.skipAndTakeQueryStats.totalCount)
          : SmdAPI.getSitesByFilters(
              { ownerCompanyIdCollection: [ownerCompanyId] },
              {
                includedSiteRelation: true,
                ownerCompanyRelationStatus: true,
                siteActive: true,
                hasNoCertificate: false,
                hasCertificateThatExpireSoon: true,
                hasCertificateWithNoEndDate: false,
              }
            ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
        isRetailer
          ? SmdAPI.getRelationsByFilters(
              {
                ownerCompanyIdCollection: null,
              },
              {
                associatedCompanyId: ownerCompanyId,
                hasNoCertificate: false,
                ownerCompanyRelationStatus: true,
                hasCertificateThatExpireSoon: false,
                hasCertificateWithNoEndDate: true,
              }
            ).then(({ data }) => data.skipAndTakeQueryStats.totalCount)
          : SmdAPI.getSitesByFilters(
              { ownerCompanyIdCollection: [ownerCompanyId] },
              {
                includedSiteRelation: true,
                ownerCompanyRelationStatus: true,
                hasNoCertificate: false,
                siteActive: true,
                hasCertificateThatExpireSoon: false,
                hasCertificateWithNoEndDate: true,
              }
            ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
      ]);

      return {
        missingCount,
        expiringCount,
        noDateCount,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);

export const fetchAllCompaniesSupplier = createAsyncThunk(
  "siteList/fetchAllCompaniesSupplier",
  async ({ ownerCompanyId }: { ownerCompanyId: string }) => {
    const { data: supplierCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {
        relatedOwnerCompanyId: ownerCompanyId,
        companyActivityId: CompanyActivityId.SUPPLIER,
        includePrivate: true,
      }
    );
    return supplierCompanies.companies;
  }
);
