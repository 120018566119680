import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectDocuments } from "reduxStore/documentList/selectors";

import styles from "../../SiteRetailerDetails.module.less";

import ContactsBloc from "./components/ContactsBloc";
import PrivateInformation from "./components/PrivateInformation";
import RecentDocuments from "./components/RecentDocuments/RecentDocuments";
import SupplierBloc from "./components/SiteIdentification/SupplierBloc";

const SiteInformationsTab = () => {
  const { documentRead } = usePermissions();
  const documents = useSelector(selectDocuments);

  return (
    <div className={styles.tabWrapper}>
      <Row gutter={[32, 32]}>
        <Col {...PageCol.HALF}>
          <PrivateInformation />
        </Col>
        <Col {...PageCol.HALF}>
          <Row gutter={[32, 32]}>
            {!!documents.length && documentRead && (
              <Col {...PageCol.FULL}>
                <RecentDocuments documents={documents} />
              </Col>
            )}
            <Col {...PageCol.FULL}>
              <ContactsBloc />
            </Col>
            <Col {...PageCol.FULL}>
              <SupplierBloc />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SiteInformationsTab;
