import React, { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import {
  Heading,
  Button,
  TagV1 as Tag,
  Alert,
  Typography,
} from "@trace-one/design-system";
import { Form, Radio } from "antd";
import BrandFormModal from "pages/Brands/pages/BrandList/BrandFormModal";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import {
  selectBrandsData,
  selectLanguagesData,
  selectNetContentMeasuresData,
  selectCustomProductTypes,
} from "reduxStore/shared/selectors";

import CategoryTreeSelect from "components/CategoryTreeSelect";
import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import Select from "components/Select";
import { FormInstanceType } from "shared/hooks/useAntForm";

import { SectionsIds } from "../../constants";
import { ProductFormValues } from "../../models";

import styles from "./IdentificationSection.module.less";
import NetContentsInput from "./NetContentsInput";

// interface TradeItemStatusRadioProps extends Omit<RadioGroupProps, "onChange"> {
//   onChange?: (value: string) => void;
//   onConfirm?: Function;
//   form?: any;
// }

interface IdentificationSectionProps {
  submitResult: any;
  isEditForm: boolean;
  initialValues: ProductFormValues;
  disabled: boolean;
  form: FormInstanceType<ProductFormValues>;
  companySettings?: CompanySettings;
}

// const TradeItemStatusRadio: React.FC<TradeItemStatusRadioProps> = ({
//   onChange,
//   onConfirm,
//   form,
//   ...rest
// }) => {
//   const intl = useIntl();
//   const [popconfirmVisible, setPopconfirmVisible] = useState(false);
//   const handleChange = event => {
//     const tradeItemStatus = event.target.value;
//     if (tradeItemStatus === TradeItemStatus.ACTIVE) {
//       onChange(tradeItemStatus);
//     } else if (tradeItemStatus === TradeItemStatus.INACTIVE) {
//       setPopconfirmVisible(true);
//     }
//   };

//   return (
//     <Radio.Group onChange={handleChange} {...rest}>
//       <ConfirmationPopover
//         visible={popconfirmVisible}
//         onVisibleChange={visible => {
//           if (!visible) {
//             setPopconfirmVisible(false);
//           }
//         }}
//         contentMessage={intl.formatMessage(
//           {
//             id: "productForm.productIdentification.statusArchive.confirm",
//           },
//           {
//             b: (...chunks) => <b>{chunks}</b>,
//           }
//         )}
//         onConfirmation={() => {
//           onChange(TradeItemStatus.INACTIVE);
//           onConfirm();
//           setPopconfirmVisible(false);
//         }}
//         onCancel={() => setPopconfirmVisible(false)}
//       >
//         <Radio
//           value={TradeItemStatus.INACTIVE}
//           data-test-id="md-product-tradeItemStatus-inactive"
//         >
//           {intl.formatMessage({
//             id: "general.yes",
//           })}
//         </Radio>
//       </ConfirmationPopover>

//       <Radio
//         value={TradeItemStatus.ACTIVE}
//         style={{ marginRight: 30 }}
//         data-test-id="md-product-tradeItemStatus-active"
//       >
//         {intl.formatMessage({
//           id: "general.no",
//         })}
//       </Radio>
//     </Radio.Group>
//   );
// };

const IdentificationSection: React.FC<IdentificationSectionProps> = ({
  submitResult,
  isEditForm,
  initialValues,
  disabled,
  form,
  companySettings,
}) => {
  const intl = useIntl();
  const { data: brands } = useSelector(selectBrandsData);
  const { data: customProductTypes } = useSelector(selectCustomProductTypes);
  const [isCreateBrandOpen, setIsCreateBrandOpen] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const [isGtinValid, setIsGtinValid] = useState<boolean>(null);
  const [gtinAlert, setGtinAlert] = useState<boolean>(null);
  const { data: netContentMeasures } = useSelector(
    selectNetContentMeasuresData
  );
  const { productFormError, productFormData } = useSelector(
    selectProductDetailsData
  );
  const languageOptions = useSelector(selectLanguagesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      name: text,
    })
  );

  const { brandsWrite } = usePermissions();
  const formData = form && form.getFieldsValue();
  const hasError =
    productFormError?.tradeName || formData?.tradeItemName === "";
  const getTagName = (brandname, isActive) => {
    const tagName = !isActive && (
      <Tag
        label={intl.formatMessage({ id: "general.archived" })}
        color="grey"
        mode="light"
      />
    );
    return (
      <div className={styles.brandTag}>
        {brandname} {tagName}
      </div>
    );
  };

  useEffect(() => {
    if (selectedBrand) {
      form.setFieldsValue({ brandId: selectedBrand });
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (!isEditForm && companySettings?.defaultLanguage) {
      form.setFieldsValue({
        productLanguage: companySettings?.defaultLanguage,
      });
    }
  }, [companySettings]);

  const valdiateGtin = () => {
    PmdAPI.validateTradeitemGtin(formData.gtin).then(({ data }) => {
      setIsGtinValid(data.isValid);
      setGtinAlert(true);
    });
  };

  return (
    <div className={styles.root}>
      <FormWrapper.Section
        id={SectionsIds.IDENTIFICATION}
        title={
          <Heading size="m">
            {intl.formatMessage({
              id: "productForm.productIdentification.title",
            })}{" "}
          </Heading>
        }
      >
        <Form.Item
          name="tradeItemName"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.name" })}{" "}
            </Heading>
          }
          rules={[
            {
              required: true,
              whitespace: true,
              message: "",
            },
          ]}
        >
          <Input
            maxLength={256}
            data-test-id="md-product-tradeItemName"
            disabled={disabled}
            error={hasError}
            errorDataTestId="md-error-tradeItemName-value-is-required"
            errorMessage={intl.formatMessage({ id: "general.valueIsRequired" })}
          />
        </Form.Item>
        <div style={{ position: "relative" }}>
          <Form.Item
            name="brandId"
            label={
              <Heading size="xxs">
                {" "}
                {intl.formatMessage({ id: "general.brand" })}
              </Heading>
            }
            labelAlign="left"
            rules={
              companySettings?.isBrandMandatory && [
                {
                  required: true,
                  whitespace: true,
                  message: intl.formatMessage({
                    id: "general.valueIsRequired",
                  }),
                  size: "10px",
                },
              ]
            }
            {...submitResult.brandId}
          >
            <Select
              data-test-id="md-product-brandId"
              options={brands
                .filter(
                  item => item.isActive || item.id === productFormData.brandId
                )
                .map(({ id, brandName, isActive }) => ({
                  value: id,
                  name: getTagName(brandName, isActive),
                  title: brandName,
                }))}
              showSearch
              disabled={disabled}
              filterOption={(input, option) =>
                (option?.name?.props?.children[0] ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          {brandsWrite && (
            <Button
              data-test-id="md-model-createBrand"
              style={{ position: "absolute", left: "800px", top: "10px" }}
              type="link"
              onClick={() => setIsCreateBrandOpen(true)}
            >
              {intl.formatMessage({ id: "brandList.createBrand" })}
            </Button>
          )}
        </div>

        {/* <Form.Item
        name="productLanguage"
        label={intl.formatMessage({
          id: "productForm.productIdentification.productLanguage",
        })}
      >
        <Select
          placeholder={
            !isEditForm ? intl.formatMessage({ id: "general.selectValue" }) : ""
          }
          data-test-id="md-product-productLanguage"
          options={languages.map(({ itemCode, text }) => ({
            value: itemCode,
            label: text,
          }))}
          allowClear
          disabled={isEditForm}
        />
      </Form.Item> */}

        <Form.Item
          name="isTradeItemBaseUnit"
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "productForm.productIdentification.baseUnit",
              })}{" "}
            </Heading>
          }
        >
          <Radio.Group disabled={disabled}>
            <Radio
              value={true}
              style={{ marginRight: 30 }}
              data-test-id="md-product-isTradeItemBaseUnit-yes"
            >
              {intl.formatMessage({
                id: "general.yes",
              })}
            </Radio>
            <Radio
              value={false}
              data-test-id="md-product-isTradeItemBaseUnit-no"
            >
              {intl.formatMessage({
                id: "general.no",
              })}
            </Radio>
          </Radio.Group>
        </Form.Item>

        {companySettings?.isMultipackMandatory && (
          <Form.Item
            name="multipack"
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productForm.productIdentification.multiPack",
                })}{" "}
              </Heading>
            }
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "general.valueIsRequired",
                }),
              },
            ]}
          >
            <Radio.Group
              disabled={
                disabled || (isEditForm && initialValues.multipack !== null)
              }
            >
              <Radio
                value={true}
                style={{ marginRight: 30 }}
                data-test-id="md-product-multipack-yes"
              >
                {intl.formatMessage({
                  id: "general.yes",
                })}
              </Radio>
              <Radio value={false} data-test-id="md-product-multipack-no">
                {intl.formatMessage({
                  id: "general.no",
                })}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          name="isConsumerUnit"
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "productForm.productIdentification.consumerUnit",
              })}{" "}
            </Heading>
          }
        >
          <Radio.Group disabled={disabled}>
            <Radio
              value={true}
              style={{ marginRight: 30 }}
              data-test-id="md-product-isConsumerUnit-yes"
            >
              {intl.formatMessage({ id: "general.yes" })}
            </Radio>
            <Radio value={false} data-test-id="md-product-isConsumerUnit-no">
              {intl.formatMessage({ id: "general.no" })}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "productForm.productIdentification.GTIN",
              })}{" "}
            </Heading>
          }
          labelAlign="left"
          {...submitResult.gtin}
        >
          <div className={styles.gtinContainer}>
            <Form.Item name="gtin">
              <Input
                disabled={disabled}
                maxLength={20}
                onChange={() => setGtinAlert(false)}
                data-test-id="md-product-gtin"
              />
            </Form.Item>
            <Button
              style={{ marginLeft: 10 }}
              disabled={!form.getFieldValue("gtin") || disabled}
              type="secondary"
              data-test-id="md-validateGTIN"
              onClick={valdiateGtin}
            >
              {intl.formatMessage({ id: "productDetails.validate.GTIN" })}
            </Button>
          </div>
          {gtinAlert && (
            <Alert
              className={styles.gtinAlert}
              message={
                isGtinValid
                  ? intl.formatMessage({
                      id: "productDetails.GTIN.valid",
                    })
                  : intl.formatMessage(
                      {
                        id: "productDetails.GTIN.invalid.description",
                      },
                      {
                        b: (...chunks) => (
                          <Typography
                            variant="link-s"
                            component="a"
                            target="_blank"
                            href="https://www.gs1.org/services/how-calculate-check-digit-manually"
                          >
                            {chunks}
                          </Typography>
                        ),
                      }
                    )
              }
              type={isGtinValid ? "success" : "warning"}
              closable={true}
              showIcon={true}
              afterClose={() => setGtinAlert(false)}
              data-test-id="md-validateGTIN-alert"
            />
          )}
        </Form.Item>
        {companySettings?.isCustomProductTypeMandatory && (
          <Form.Item
            name="customProductTypeId"
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productDetails.customProductType",
                })}
              </Heading>
            }
            labelAlign="left"
            rules={
              companySettings?.isCustomProductTypeMandatory && [
                {
                  required: true,
                  whitespace: true,
                  message: intl.formatMessage({
                    id: "general.valueIsRequired",
                  }),
                },
              ]
            }
          >
            <Select
              data-test-id="md-product-customProductTypeId"
              options={customProductTypes.map(({ id, text }) => ({
                value: id,
                name: text,
                title: text,
              }))}
              showSearch
              disabled={disabled}
              filterOption={(input, option) =>
                (option?.name?.props?.children[0] ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
        <Form.Item
          name="productCategories"
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "general.productCategory",
              })}{" "}
            </Heading>
          }
          {...submitResult.productCategories}
          rules={
            companySettings?.isProductCategoryMandatory && [
              {
                required: true,
                message: intl.formatMessage(
                  {
                    id: "productForm.productIdentification.productCategory.errorMsg",
                  },
                  {
                    minimumLevelProductCategoryRequired:
                      companySettings?.minimumLevelProductCategoryRequired,
                  }
                ),
              },
            ]
          }
        >
          {/* @ts-ignore */}
          <CategoryTreeSelect
            disabled={disabled}
            multiple
            categorySelection={false}
            data-test-id="md-product-categories"
          />
        </Form.Item>

        {companySettings?.defaultLanguage && (
          <Form.Item
            name="productLanguage"
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productForm.productIdentification.productLanguage",
                })}
              </Heading>
            }
            rules={
              (companySettings?.isProductLanguageMandatory ||
                companySettings?.isMultipackMandatory) && [
                {
                  required: true,
                  whitespace: true,
                  message: intl.formatMessage({
                    id: "general.valueIsRequired",
                  }),
                },
              ]
            }
          >
            <Select
              disabled={
                disabled ||
                (isEditForm && initialValues.productLanguage !== null)
              }
              data-test-id="md-product-language"
              options={languageOptions.filter(item =>
                companySettings?.availableProductLanguages?.includes(item.value)
              )}
              showSearch
              filterOption={(input, option) =>
                (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}

        {/* {isEditForm && (
          <Form.Item
            name="tradeItemStatusId"
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productForm.productIdentification.isProductArchived",
                })}{" "}
              </Heading>
            }
          >
            <TradeItemStatusRadio
              data-test-id="md-product-tradeItemStatusId"
              disabled={disabled}
              form={form}
              onConfirm={() => {
                const fields = form.getFieldsValue();
                fields.manufacturedItems = initialManufacturedItems
                  ?.filter(({ id }) => id)
                  .map(item => ({
                    ...item,
                    manufacturedItemStatusId: ManufacturingItemStatus.INACTIVE,
                  }));

                form.setFieldsValueAndMarkDirty(fields);
              }}
            />
          </Form.Item>
        )} */}

        <Form.Item
          name="netContents"
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "productForm.productIdentification.netContents",
              })}{" "}
            </Heading>
          }
        >
          {/* @ts-ignore */}
          <NetContentsInput
            disabled={disabled}
            netContentMeasures={netContentMeasures}
          />
        </Form.Item>
      </FormWrapper.Section>

      <BrandFormModal
        visible={isCreateBrandOpen}
        onCancel={() => setIsCreateBrandOpen(false)}
        setSelectedBrand={setSelectedBrand}
        isEditBrand={isCreateBrandOpen}
      />
    </div>
  );
};

export default memo(IdentificationSection);
