import { State } from "./models";

export const initialState: State = {
  isProductLoading: false,
  productData: {},
  productSupplierData: {
    data: {},
    isLoading: false,
    errorStatus: null,
    hasError: false,
  },
  hasProductError: false,
  errorStatus: null,
  productFormData: {},
  productFormError: { tradeName: false, manufacturedItem: null },
  productBrandId: "",
};
